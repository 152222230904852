import React from "react";
import "./main.scss";
import Navigation from "./components/navigation.jsx";
import Header from "./components/header.jsx";
import Footer from "./components/footer.jsx";
import Home from "./pages/home.jsx";
import Treatment from "./pages/treatment.jsx";
import About from "./pages/about.jsx";
import Contact from "./pages/contact.jsx";
import Reference from "./pages/reference.jsx";
import Neurofeedback from "./pages/neurofeedback.jsx";
import { Route, Switch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

class App extends React.Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Navigation />
        <Switch>
          <Route path="/treatment" component={Treatment} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/neurofeedback" component={Neurofeedback} />
          <Route path="/reference" component={Reference} />
          <Route path="/" exact component={Home} />
          <Route path="*" component={Home} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
