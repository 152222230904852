import React from "react";
import { AiFillPhone } from "react-icons/ai";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <Link className="btn-text" to="/contact">
      <AiFillPhone style={{ fontSize: "1.8rem", marginRight: ".5rem" }} />{" "}
      Schedule Treatment Now
    </Link>
  );
};

export default CTA;
