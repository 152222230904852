import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FcIdea } from "react-icons/fc";
import ReactPlayer from "react-player";

const Home = () => {
  return (
    <div className="home">
      <Helmet htmlAttributes>
        <title>East Carolina Brain Center | Home</title>
        <meta
          name="description"
          content="We can help your chronic condition! Over 25 years experience helping people overcome sypmtoms of Functional Brian Disorders"
        />
        <meta
          name="keywords"
          content="neurologist in Greenville, Functional Medicine doctor in Greenville, Neurofeedback doctor in Greenville, Greenville doctor, Greenville functional medicine doctor, Greenville Chiropractor, Greenville neurofeedback doctor, help for ADD in Greenville, help for anxiety in Greenville, help for autism in Greenville, help for addiction in Greenville, help for brain injury in Greenville, help for chronic pain in Greenville, help for depression in Greenville, help for fibromyalgia in Greenville, help for Insomnia in Greenville, help for memory loss in Greenville, help for migraines in Greenville, help for PTSD in Greenville, help for Stress in Greenville, help for stroke in Greenville, help for schizophrenia in Greenville, help for learning disorders in Greenville"
        />
        <link
          rel="canonical"
          href="https://eastcarolinabraincenter.com/"
        ></link>
      </Helmet>
      <div className="brain"></div>
      <div className="content">
        <h2 className="heading-primary">Neurotherapy</h2>
        <h2 className="heading-secondary">
          For functional brain disorders . . .
        </h2>
        <div className="symptoms">
          <ul>
            <li>ADD/ADHD</li>
            <li>Anxiety</li>
            <li>Autism/ASD</li>
            <li>Bad Memory</li>
            <li>Bipolar Disorder</li>
            <li>Chronic Fatigue</li>
            <li>Chronic Migraines</li>
            <li>Concussion</li>
            <li>Depression</li>
            <li>Fibromyalgia</li>
            <li>Mood Swings</li>
            <li>OCD</li>
            <li>Panic Attacks</li>
            <li>Parkinson's</li>
            <li>PTSD</li>
            <li>Tics/Tourette's</li>
          </ul>
          <h4 className="heading-secondary"> . . . and more</h4>
        </div>
        <div className="content__container">
          <h4
            className="heading-primary"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-duration="1600"
          >
            What is a functional brain disorder?
          </h4>
          <div className="p">
            A Functional Brain Disorder (FBD) is a neurological disorder that
            can impact a person's ability to function at normal levels, which in
            turn can detract from an individual's ability to perform at their
            highest potential on a daily basis. FBDs can result from physical or
            emotional trauma, poor brain development, genetic or environmental
            influences, degeneration of brain cells, or interruption of the
            communication between the brain areas, to name a few. &nbsp;
            <div className="mobile">
              &nbsp;
              <hr />
            </div>
            There is nothing visibly wrong with how the brain looks on an MRI/CT
            scan, however symptoms like those listed above might be experienced
            or present. Our human abilities, behaviors, and experiences are
            shaped from the electrical activity of our brains, so it isn't
            surprising that faulty brain activity can lead to unwanted
            experiences, bothersome behaviors, and substandard abilities.
          </div>
        </div>
        <img
          loading="lazy"
          alt="scatter brained"
          src={require("../assets/disconnect.jpg").default}
          className="img-default"
        />
        <div className="content__container">
          <h4
            className="heading-primary"
            data-aos="fade-right"
            data-aos-offset="50"
            data-aos-duration="1600"
          >
            What is Neurotherapy?
          </h4>
          <div className="p">
            Neurotherapy is the clinical application of neuroscience. By
            examining the structure and function of the human brain and nervous
            system a map of the networks in the brain is created. Neurotherapy
            focuses on understanding how FBDs occur and which areas of the brain
            aren't functioning properly.&nbsp;
            <div className="mobile">
              &nbsp;
              <hr />
            </div>
            After answering these questions on an individual basis, personalized
            neurofeedback therapy is used to guide the brain back to an optimal
            state of functioning. Neurotherapy uses the brain's own natural
            capacity to learn (neuroplasticity) through positively reinforced
            experiences to correct brain function with the goal of alleviating
            the symptoms experienced from FBDs.
          </div>
        </div>
        {/* <h4 className="heading-primary">Neurotherapy in ~5 minutes:</h4> */}
        <Link to="/treatment" className="btn-text">
          <h3>
            {" "}
            <FcIdea className="icon" />
            Treatment &rarr;
          </h3>
        </Link>
        <h4
          className="heading-primary"
          data-aos="fade-up"
          data-aos-offset="50"
          data-aos-duration="1600"
        >
          Neurotherapy in 5 minutes
        </h4>
        <div class="video">
          <ReactPlayer controls url="./videos/neuroplasticity.mp4" />
        </div>
        {/* <div className="content__about">
          <img
            src={require("../assets/profile.jpg").default}
            alt="Dr. Young"
            className="content__proPic"
          />
          <div className="content__container">
            <p>
              <Link to="/about" className="link">
                Dr. Young
              </Link>{" "}
              is a board certified chiropractic neurologist (DACNB) and
              neurotherapist (BCIA-BCN) with extensive training and
              certifications in functional medicine, acupuncture and restoration
              of the locomotor system for over 32 years. Specializing in the
              health and training of the brain!
            </p>
          </div>
        </div> */}
        <div className="about-card content__container">
          <div className="p">
            <Link to="/about" className="link link-big ">
              Dr. Joe Young
            </Link>{" "}
            specializes in the health and training of the brain. He has been
            certified in functional neurology, Quantitative Electroencephalogram
            (qEEG), and neurofeedback therapies. He has extensive training and
            certifications in functional medicine, acupuncture, and restoration
            of the locomotor system with over 33 years of experience.
          </div>
          <img
            loading="lazy"
            src={require("../assets/profile_v2.jpg").default}
            alt="Dr. Young"
            className="proPic"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

// How do you know it is a Functional Brain Disorder?
// Our human abilities, behaviors and experiences are shaped from the electrical activity of our brains. So it shouldn’t come as a surprise that faulty brain activity leads to Functional Brain Disorders, such as            • unwanted experiences • bothersome behaviors and • substandard abilities.

// Quantitative EEG (QEEG) is the best method for analyzing the electrical activity of the brain for problems  in:  generating energy for processing information; making connections with other areas of the brain networks; sending and receiving clear information in a timely manner.
