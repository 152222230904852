import React from "react";
import { Helmet } from "react-helmet";

const Neurofeedback = () => {
  return (
    <div className="neurofeedback">
      <Helmet>
        <title>ECN Biofeedback</title>
        <meta
          name="description"
          content="A deep dive into neurotherapy and using biofeedback to treat functional brain disorders."
        />
      </Helmet>
      Neurofeedback
    </div>
  );
};

export default Neurofeedback;
