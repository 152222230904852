import React from "react";
import { Helmet } from "react-helmet";
import CTA from "../components/callToAction.jsx";

const About = () => {
  return (
    <div className="content content__container">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>East Carolina Brain Center | About</title>
        <meta
          name="description"
          content="Dr. Joseph Young has been providing people with positive, knowledgeable, and trusted health care for over 33 years. He is pleased to be in Greenville and committed to helping you achieve your personal optimum level of health."
        />
        <meta
          name="keywords"
          content="doctor in greenville,chiropractor in greenville,functional medicine doctor in greenville,neurofeedback doctor in greenville,neurology doctor in greenville"
        />
        <link
          rel="canonical"
          href="https://eastcarolinabraincenter.com/about-us/"
        />
      </Helmet>
      {/* <h2 className="heading-primary">About</h2> */}
      <div className="about__picture" />
      <h2 className="heading-primary">
        Dr. Joseph Young <br /> DC, BCN, FIAMA, CFMP
      </h2>
      <div className="p">
        Dr. Joseph Young has been providing people with positive, knowledgeable,
        trusted health care for over 27 years. He is pleased to be in Greenville
        committing to being your trusted advisor in helping you achieve your
        personal optimum level of health.{" "}
        <div className="mobile">
          &nbsp;
          <hr />
        </div>
        Dr. Young began learning of the benefits of holistic treatment at a
        young age. He was suffering from months of severe, incapacitating
        headaches with little relief from medications. Then his mother took him
        to a doctor of chiropractic. “At the time I did not know what to expect…
        but the relief after the treatment was amazing to me! The terrible head
        pain was gone! This experience inspired me to continue searching for
        natural methods that work in restoring and improving function of our
        brains”
      </div>
      <h2 className="heading-primary">Awards and Certifications:</h2>
      <div className="about__list content content__container">
        <ul>
          <li>
            Advanced Studies in Diagnosis and Clinical Management of Cervical
            Trauma, 1992
          </li>
          <li>
            Chiropractic Rehabilitation of the Locomotor System certification
            levels I and II, 1996/1997
          </li>
          <li>Vestibular Testing and Rehabilitation Therapy, 1999</li>
          <li>
            Fellow of the International Academy of Medical Acupuncture, 2001
          </li>
          <li>
            Board Eligible for Diplomate in Clinical Nutrition, 2010 (Studies in
            Functional Blood Chemistry Analysis, Functional Endocrinology,
            Thyroid Disorders, Neurotransmitters and Brain)
          </li>
          <li>Certified Gluten Practitioner, 2011</li>
          <li>Certified in Practice of Functional Medicine, 2012</li>
          <li>
            Board Certified Diplomate of Chiropractic Neurology, DACNB 2014-2020
          </li>
          <li>
            Traumatic Brain Injuries certification course, under the instruction
            of Dr. Frederick Carrick of The Carrick Institute.
          </li>
          <li>
            Board Certified in Neuro-feedback. Biofeedback Certification
            International Alliance.
          </li>
        </ul>
      </div>
      <div className="p">
        “I believe the new frontier for health lies within our brain and its
        connection with our body. The ability to comprehend what our senses
        experience and to express our thoughts, emotions and actions to the
        fullest extent of our humanity relies on our brain. I have a passion for
        understanding the neuro-physiological mechanisms of brain disorders and
        in restoring hope and possibilities to those people who are living with
        a brain disorder due to poor development, disease, degeneration,
        physical or emotional trauma.”{" "}
        <div className="mobile">
          &nbsp;
          <hr />
        </div>
        “I am committed to helping bring the benefits of the most cutting edge
        scientific knowledge, insight and training to the people who are in need
        of hope and helping them in regaining their possibilities of living a
        more fulfilling life. “
      </div>
      <CTA />
    </div>
  );
};

export default About;
