import React from "react";
import { FaFacebookSquare, FaGooglePlusSquare } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <h3>East Carolina Brain Center</h3>
      <ul>
        <li>
          <HiLocationMarker className="icon icon-footer" />
          Greenville, NC
        </li>
        <li>
          <FaPhoneAlt className="icon icon-footer" />
          <a href="tel:252-412-3463" rel="nofollow" className="phone-link">
            252-412-3463
          </a>
        </li>
      </ul>
      <p>By Appointment Only</p>
      <p>Flexible hours available</p>
      <div className="footer__socials">
        <a
          href="https://www.facebook.com/East-Carolina-Brain-Center-1591236917794501/"
          className="footer__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGooglePlusSquare />
        </a>
        <a
          href="https://www.facebook.com/East-Carolina-Brain-Center-1591236917794501/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__link"
        >
          <FaFacebookSquare />
        </a>
      </div>
    </div>
  );
};

export default Footer;
