import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="header__title">
        <h1>East Carolina Brain Center</h1>
      </Link>
    </div>
  );
};

export default Header;
