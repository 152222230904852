import React from "react";
import { Helmet } from "react-helmet";

const Reference = () => {
  let items = [
    {
      title: "ATTENTION DEFICIT/HYPERACTIVITY DISORDERS (ADHD)",
      articles: [
        "Albrecht, J. S., Bubenzer-Busch, S., Gallien, A., Knospe, E. L., Gaber, T. J., & Zepf, F. D. (2017). Effects of a structured 20-session slow-cortical-potential-based neurofeedback program on attentional performance in children and adolescents with attention-deficit hyperactivity disorder: Retrospective analysis of an open-label pilot-approach and 6-month follow-up. Neuropsychiatric Disease and Treatment, 13, 667–683. https://doi.org/10.2147/NDT.S119694",
        "Alegria, A. A., Wulff, M., Brinson, H., Barker, G. J., Norman, L. J., Brandeis, D., … Rubia, K. (2017). Real-time fMRI neurofeedback in adolescents with attention deficit hyperactivity disorder. Human Brain Mapping, 38(6), 3190–3209. https://doi.org/10.1002/hbm.23584",
        "Alkoby, O., Abu-Rmileh, A., Shriki, O., & Todder, D. (2017). Can we predict who will respond to neurofeedback? A review of the inefficacy problem and existing predictors for successful EEG neurofeedback learning. Neuroscience. https://doi.org/10.1016/j.neuroscience.2016.12.050",
        "Arnold, L. E., Lofthouse, N., Hersch, S., Pan, X., Hurt, E., Bates, B., … Grantier, C. (2013). EEG neurofeedback for ADHD: Double-blind sham-controlled randomized pilot feasibility trial. Journal of Attention Disorders, 17(5), 410–419. https://doi.org/10.1177/1087054712446173",
        "Arns, M., Batail, J.-M., Bioulac, S., Congedo, M., Daudet, C., Drapier, D., … NExT group. (2017). Neurofeedback: One of today’s techniques in psychiatry? L’Encephale, 43(2), 135–145. https://doi.org/10.1016/j.encep.2016.11.003",
        "Arns, M., Conners, C. K., & Kraemer, H. C. (2013). A decade of EEG theta/beta ratio research in ADHD: A meta-analysis. Journal of Attention Disorders, 17(5), 374–383. https://doi.org/10.1177/1087054712460087",
        "Arns, M., de Ridder, S., Strehl, U., Breteler, M., & Coenen, A. (2009). Efficacy of neurofeedback treatment in ADHD: The effects on inattention, impulsivity and hyperactivity, a meta-analysis. Clinical EEG and Neuroscience, 40(3), 180–189. https://doi.org/10.1177/155005940904000311",
        "Arns, M., Drinkenburg, W., & Leon Kenemans, J. (2012). The effects of QEEG-informed neurofeedback in ADHD: an open-label pilot study. Applied Psychophysiology and Biofeedback, 37(3), 171–180. https://doi.org/10.1007/s10484-012-9191-4",
        "Arns, M., Gunkelman, J., Breteler, M., & Spronk, D. (2008). EEG phenotypes predict treatment outcome to stimulants in children with ADHD. Journal of Integrative Neuroscience, 7(3), 421–438.",
        "Arns, Martijn, Heinrich, H., & Strehl, U. (2014). Evaluation of neurofeedback in ADHD: The long and winding road. Biological Psychology, 95, 108–115. https://doi.org/10.1016/j.biopsycho.2013.11.013",
        "Arns, Martijn, & Kenemans, J. L. (2014). Neurofeedback in ADHD and insomnia: vigilance stabilization through sleep spindles and circadian networks. Neuroscience and Biobehavioral Reviews, 44, 183–194. https://doi.org/10.1016/j.neubiorev.2012.10.006",
        "Bakhshayesh, A. R., Hänsch, S., Wyschkon, A., Rezai, M. J., & Esser, G. (2011). Neurofeedback in ADHD: A single-blind randomized controlled trial. European Child & Adolescent Psychiatry, 20(9), 481–491. https://doi.org/10.1007/s00787-011-0208-y",
        "Bakhtadze, S., Beridze, M., Geladze, N., Khachapuridze, N., & Bornstein, N. (2016). Effect of EEG biofeedback on cognitive flexibility in children with attention deficit hyperactivity disorder with and without epilepsy. Applied Psychophysiology And Biofeedback, 41(1), 71–79. https://doi.org/10.1007/s10484-015-9314-9",
        "Barth, B., Mayer, K., Strehl, U., Fallgatter, A. J., & Ehlis, A.-C. (2017). EMG biofeedback training in adult attention-deficit/hyperactivity disorder: An active (control) training? Behavioural Brain Research, 329, 58–66. https://doi.org/10.1016/j.bbr.2017.04.021",
        "Baumeister, S., Wolf, I., Holz, N., Boecker-Schlier, R., Adamo, N., Holtmann, M., … Brandeis, D. (2016). Neurofeedback training effects on inhibitory brain activation in ADHD: A matter of learning? Neuroscience. https://doi.org/10.1016/j.neuroscience.2016.09.025",
        "Bazanova, O. M., Auer, T., & Sapina, E. A. (2018). On the efficiency of Individualized theta/beta ratio neurofeedback combined with forehead EMG training in ADHD children. Frontiers in Human Neuroscience, 12, 3. https://doi.org/10.3389/fnhum.2018.00003",
        "Beauregard, M., & Lévesque, J. (2006). Functional magnetic resonance imaging investigation of the effects of neurofeedback training on the neural bases of selective attention and response inhibition in children with attention-deficit/hyperactivity disorder. Applied Psychophysiology and Biofeedback, 31(1), 3–20. https://doi.org/10.1007/s10484-006-9001-y",
        "Berger, A. M., & Davelaar, E. J. (2017). Frontal alpha oscillations and attentional control: A virtual reality neurofeedback study. Neuroscience. https://doi.org/10.1016/j.neuroscience.2017.06.007",
        "Bink, M., Bongers, I. L., Popma, A., Janssen, T. W. P., & van Nieuwenhuizen, C. (2016). 1-year follow-up of neurofeedback treatment in adolescents with attention-deficit hyperactivity disorder: randomised controlled trial. BJPsych Open, 2(2), 107–115. https://doi.org/10.1192/bjpo.bp.115.000166",
        "Bink, M., van Nieuwenhuizen, C., Popma, A., Bongers, I. L., & van Boxtel, G. J. M. (2015). Behavioral effects of neurofeedback in adolescents with ADHD: a randomized controlled trial. European Child & Adolescent Psychiatry, 24(9), 1035–1048. https://doi.org/10.1007/s00787-014-0655-3",
        "Blume, F., Hudak, J., Dresler, T., Ehlis, A.-C., Kühnhausen, J., Renner, T. J., & Gawrilow, C. (2017). NIRS-based neurofeedback training in a virtual reality classroom for children with attention-deficit/hyperactivity disorder: study protocol for a randomized controlled trial. Trials, 18(1), 41. https://doi.org/10.1186/s13063-016-1769-3",
        "Bluschke, A., Broschwitz, F., Kohl, S., Roessner, V., & Beste, C. (2016). The neuronal mechanisms underlying improvement of impulsivity in ADHD by theta/beta neurofeedback. Scientific Reports, 6, 31178. https://doi.org/10.1038/srep31178",
        "Bralten, J., Franke, B., Waldman, I., Rommelse, N., Hartman, C., Asherson, P., … Arias-Vásquez, A. (2013). Candidate genetic pathways for attention-deficit/hyperactivity disorder (ADHD) show association to hyperactive/impulsive symptoms in children with ADHD. Journal of the American Academy of Child and Adolescent Psychiatry, 52(11), 1204-1212.e1. https://doi.org/10.1016/j.jaac.2013.08.020",
        "Buyck, I., & Wiersema, J. R. (2015). Task-related electroencephalographic deviances in adults with attention deficit hyperactivity disorder. Neuropsychology, 29(3), 433–444. https://doi.org/10.1037/neu0000148",
        "Canady, V. A. (2018). Research finds neurofeedback viable therapy option for children with ADHD. Mental Health Weekly, 28(10), 1–8. https://doi.org/10.1002/mhw.31371",
        "Carelli, L., Solca, F., Faini, A., Meriggi, P., Sangalli, D., Cipresso, P., … Poletti, B. (2017). Brain-computer interface for clinical purposes: Cognitive assessment and rehabilitation. BioMed Research International, 2017, 1695290. https://doi.org/10.1155/2017/1695290",
        "Carmody, D. P., Radvanski, D. C., Wadhwani, S., Sabo, M. J., & Vergara, L. (2000). EEG biofeedback training and attention-deficit/hyperactivity disorder in an elementary school setting. Journal of Neurotherapy, 4(3), 5–27. https://doi.org/10.1300/J184v04n03_02",
        "Catalá-López, F., Hutton, B., Núñez-Beltrán, A., Page, M. J., Ridao, M., Macías Saint-Gerons, D., … Moher, D. (2017). The pharmacological and non-pharmacological treatment of attention deficit hyperactivity disorder in children and adolescents: A systematic review with network meta-analyses of randomised trials. PloS One, 12(7), e0180355. https://doi.org/10.1371/journal.pone.0180355",
        "Cheung, C. H. M., Rijsdijk, F., McLoughlin, G., Brandeis, D., Banaschewski, T., Asherson, P., & Kuntsi, J. (2016). Cognitive and neurophysiological markers of ADHD persistence and remission. The British Journal of Psychiatry: The Journal of Mental Science, 208(6), 548–555. https://doi.org/10.1192/bjp.bp.114.145185",
        "Christiansen, H., Reh, V., Schmidt, M. H., & Rief, W. (2014). Slow cortical potential neurofeedback and self-management training in outpatient care for children with ADHD: Study protocol and first preliminary results of a randomized controlled trial. Frontiers in Human Neuroscience, 8. https://doi.org/10.3389/fnhum.2014.00943",
        "Cortese, S., & Rosello-Miranda, R. (2017). Treatments for children and adolescents with attention deficit hyperactivity disorder: What is the evidence base to date? Revista De Neurologia, 64(s01), S3–S7.",
        "Cortese, S., Ferrin, M., Brandeis, D., Holtmann, M., Aggensteiner, P., Daley, D., … Sonuga-Barke, E. J. S. (2016). Neurofeedback for attention-deficit/hyperactivity disorder: Meta-analysis of clinical and neuropsychological outcomes from randomized controlled trials. Journal of the American Academy of Child & Adolescent Psychiatry, 55(6), 444–455. https://doi.org/10.1016/j.jaac.2016.03.007",
        "Daley, K. C. (2004). Update on attention-deficit/hyperactivity disorder. Current Opinion in Pediatrics, 16(2), 217–226.",
        "Danielson, M. L., Visser, S. N., Chronis-Tuscano, A., & DuPaul, G. J. (2018). A national description of treatment among United States children and adolescents with attention-deficit/hyperactivity disorder. The Journal of Pediatrics, 192, 240-246.e1. https://doi.org/10.1016/j.jpeds.2017.08.040",
        "deBeus, R. J., & Kaiser, D. A. (2011). Chapter 5 – Neurofeedback with children with attention deficit hyperactivity disorder: A randomized double-blind placebo-controlled study. In Neurofeedback and Neuromodulation Techniques and Applications (pp. 127–152). San Diego: Academic Press. https://doi.org/10.1016/B978-0-12-382235-2.00005-6",
        "Dehghanpour, P., & Einalou, Z. (2017). Evaluating the features of the brain waves to quantify ADHD improvement by neurofeedback. Technology and Health Care: Official Journal of the European Society for Engineering and Medicine, 25(5), 877–885. https://doi.org/10.3233/THC-170845",
        "Deilami, M., Jahandideh, A., Kazemnejad, Y., Fakour, Y., Alipoor, S., Rabiee, F., … Mosavi, S. A. (2016). The effect of neurofeedback therapy on reducing symptoms associated with attention deficit hyperactivity disorder: A case series study. Basic and Clinical Neuroscience, 7(2), 167–171. https://doi.org/10.15412/J.BCN.03070211",
        "Dickhaus, T., Sannelli, C., Müller, K.-R., Curio, G., & Blankertz, B. (2009). Predicting BCI performance to study BCI illiteracy. BMC Neuroscience, 10(1), P84. https://doi.org/10.1186/1471-2202-10-S1-P84",
        "Doehnert, M., Brandeis, D., Straub, M., Steinhausen, H.-C., & Drechsler, R. (2008). Slow cortical potential neurofeedback in attention deficit hyperactivity disorder: Is there neurophysiological evidence for specific effects? Journal of Neural Transmission (Vienna, Austria: 1996), 115(10), 1445–1456. https://doi.org/10.1007/s00702-008-0104-x",
        "Drechsler, R., Straub, M., Doehnert, M., Heinrich, H., Steinhausen, H.-C., & Brandeis, D. (2007). Controlled evaluation of a neurofeedback training of slow cortical potentials in children with Attention Deficit/Hyperactivity Disorder (ADHD). Behavioral and Brain Functions, 3, 35. https://doi.org/10.1186/1744-9081-3-35",
        "Duric, N. S., Assmus, J., Gundersen, D., Duric Golos, A., & Elgen, I. B. (2017). Multimodal treatment in children and adolescents with attention-deficit/hyperactivity disorder: A 6-month follow-up. Nordic Journal of Psychiatry, 71(5), 386–394. https://doi.org/10.1080/08039488.2017.1305446",
        "Escolano, C., Navarro-Gil, M., Garcia-Campayo, J., Congedo, M., & Minguez, J. (2014). The effects of individual upper alpha neurofeedback in ADHD: An open-label pilot study. Applied Psychophysiology and Biofeedback, 39(3–4), 193–202. https://doi.org/10.1007/s10484-014-9257-6",
        "Fahimi, F., Guan, C., Wooi Boon Goh, null, Kai Keng Ang, null, Choon Guan Lim, null, & Tih Shih Lee, null. (2017). Personalized features for attention detection in children with Attention Deficit Hyperactivity Disorder. Conference Proceedings: … Annual International Conference of the IEEE Engineering in Medicine and Biology Society. IEEE Engineering in Medicine and Biology Society. Annual Conference, 2017, 414–417. https://doi.org/10.1109/EMBC.2017.8036850",
        "Fedotchev, A. I., Zemlyanaya, A. A., Polevaya, S. A., & Savchuk, L. V. (2016). [Attention deficit hyperactivity disorder and current possibilities of its treatment by the method of neurofeedback training]. Zhurnal Nevrologii I Psikhiatrii Imeni S.S. Korsakova, 116(5), 98–101.",
        "Flisiak-Antonijczuk, H., Adamowska, S., Chładzińska-Kiejna, S., Kalinowski, R., & Adamowski, T. (2015). Evaluation of the efficacy of ADHD treatment with the use of EEG-biofeedback method in comparison to methylphenidate. Archives of Psychiatry and Psychotherapy, 17(4), 32–38. https://doi.org/10.12740/APP/60511",
        "Fuchs, T., Birbaumer, N., Lutzenberger, W., Gruzelier, J. H., & Kaiser, J. (2003). Neurofeedback treatment for attention-deficit/hyperactivity disorder in children: A comparison with methylphenidate. Applied Psychophysiology and Biofeedback, 28(1), 1–12.",
        "Gani, C. (2009). Long term effects after feedback of slow cortical potentials and of Theta / Beta – amplitudes in children with Attention Deficit Hyperactivity Disorder (ADHD) (Dissertation). Universität Tübingen. Retrieved from https://publikationen.uni-tuebingen.de/xmlui/handle/10900/45553",
        "Gaume, A., Vialatte, A., Mora-Sánchez, A., Ramdani, C., & Vialatte, F. B. (2016). A psychoengineering paradigm for the neurocognitive mechanisms of biofeedback and neurofeedback. Neuroscience & Biobehavioral Reviews, 68, 891–910. https://doi.org/10.1016/j.neubiorev.2016.06.012",
        "Geladé, K., Bink, M., Janssen, T. W. P., van Mourik, R., Maras, A., & Oosterlaan, J. (2017). An RCT into the effects of neurofeedback on neurocognitive functioning compared to stimulant medication and physical activity in children with ADHD. European Child & Adolescent Psychiatry, 26(4), 457–468. https://doi.org/10.1007/s00787-016-0902-x",
        "Geladé, K., Janssen, T. W. P., Bink, M., Twisk, J. W. R., van Mourik, R., Maras, A., & Oosterlaan, J. (2017). A 6-month follow-up of an RCT on behavioral and neurocognitive effects of neurofeedback in children with ADHD. European Child & Adolescent Psychiatry. https://doi.org/10.1007/s00787-017-1072-1",
        "Geladé, K., Janssen, T. W. P., Bink, M., van Mourik, R., Maras, A., & Oosterlaan, J. (2016). Behavioral effects of neurofeedback compared to stimulants and physical activity in attention-deficit/hyperactivity disorder: A randomized controlled trial. The Journal of Clinical Psychiatry, 77(10), e1270–e1277. https://doi.org/10.4088/JCP.15m10149",
        "Gevensleben, H., Holl, B., Albrecht, B., Schlamp, D., Kratz, O., Studer, P., … Heinrich, H. (2009). Distinct EEG effects related to neurofeedback training in children with ADHD: a randomized controlled trial. International Journal of Psychophysiology: Official Journal of the International Organization of Psychophysiology, 74(2), 149–157. https://doi.org/10.1016/j.ijpsycho.2009.08.005",
        "Gevensleben, H., Holl, B., Albrecht, B., Schlamp, D., Kratz, O., Studer, P., … Heinrich, H. (2010). Neurofeedback training in children with ADHD: 6-month follow-up of a randomised controlled trial. European Child & Adolescent Psychiatry, 19(9), 715–724. https://doi.org/10.1007/s00787-010-0109-5",
        "Gevensleben, H., Holl, B., Albrecht, B., Vogel, C., Schlamp, D., Kratz, O., … Heinrich, H. (2009). Is neurofeedback an efficacious treatment for ADHD? A randomised controlled clinical trial. Journal of Child Psychology and Psychiatry, and Allied Disciplines, 50(7), 780–789. https://doi.org/10.1111/j.1469-7610.2008.02033.x",
        "Gevensleben, H., Kleemeyer, M., Rothenberger, L. G., Studer, P., Flaig-Röhr, A., Moll, G. H., … Heinrich, H. (2014). Neurofeedback in ADHD: Further pieces of the puzzle. Brain Topography, 27(1), 20–32. https://doi.org/10.1007/s10548-013-0285-y",
        "González-Castro, P., Cueli, M., Rodríguez, C., García, T., & Álvarez, L. (2016). Efficacy of neurofeedback versus pharmacological support in subjects with ADHD. Applied Psychophysiology and Biofeedback, 41(1), 17–25. https://doi.org/10.1007/s10484-015-9299-4",
        "Halder, S., Varkuti, B., Bogdan, M., Kübler, A., Rosenstiel, W., Sitaram, R., & Birbaumer, N. (2013). Prediction of brain-computer interface aptitude from individual brain structure. Frontiers in Human Neuroscience, 7, 105. https://doi.org/10.3389/fnhum.2013.00105",
        "Hanisch, C., Freund-Braier, I., Hautmann, C., Jänen, N., Plück, J., Brix, G., … Döpfner, M. (2010). Detecting effects of the indicated prevention Programme for Externalizing Problem behaviour (PEP) on child symptoms, parenting, and parental quality of life in a randomized controlled trial. Behavioural and Cognitive Psychotherapy, 38(1), 95–112. https://doi.org/10.1017/S1352465809990440",
        "Heinrich, H., Busch, K., Studer, P., Erbe, K., Moll, G. H., & Kratz, O. (2014). EEG spectral analysis of attention in ADHD: Implications for neurofeedback training? Frontiers in Human Neuroscience, 8, 611. https://doi.org/10.3389/fnhum.2014.00611",
        "Heinrich, H., Gevensleben, H., Freisleder, F. J., Moll, G. H., & Rothenberger, A. (2004). Training of slow cortical potentials in attention-deficit/hyperactivity disorder: Evidence for positive behavioral and neurophysiological effects. Biological Psychiatry, 55(7), 772–775. https://doi.org/10.1016/j.biopsych.2003.11.013",
        "Heywood, C., & Beale, I. (2003). EEG biofeedback vs. placebo treatment for attention-deficit/hyperactivity disorder: A pilot study. Journal of Attention Disorders, 7(1), 43–55. https://doi.org/10.1177/108705470300700105",
        "Hillard, B., El-Baz, A. S., Sears, L., Tasman, A., & Sokhadze, E. M. (2013). Neurofeedback training aimed to improve focused attention and alertness in children with ADHD: A study of relative power of EEG rhythms using custom-made software application. Clinical EEG and Neuroscience, 44(3), 193–202. https://doi.org/10.1177/1550059412458262",
        "Holtmann, M., Grasmann, D., Cionek-Szpak, E., Hager, V., Panzner, N., Beyer, A., … Stadler, C. (2009). Spezifische Wirksamkeit von Neurofeedback auf die Impulsivität bei ADHS [Specific effects of neurofeedback on impulsivity in ADHD]. Kindheit Und Entwicklung, 18(2), 95–104. https://doi.org/10.1026/0942-5403.18.2.95",
        "Holtmann, M., Sonuga-Barke, E., Cortese, S., & Brandeis, D. (2014). Neurofeedback for ADHD: A review of current evidence. Child and Adolescent Psychiatric Clinics of North America, 23(4), 789–806. https://doi.org/10.1016/j.chc.2014.05.006",
        "Huang, H.-L., Lu, C.-H., Tsai, H.-W., Chao, C.-C., Ho, T.-Y., Chuang, S.-F., … Yang, P.-C. (2009). Effectiveness of behavioral parent therapy in preschool children with attention-deficit hyperactivity disorder. The Kaohsiung Journal of Medical Sciences, 25(7), 357–365. https://doi.org/10.1016/S1607-551X(09)70528-5",
        "Hudak, J., Blume, F., Dresler, T., Haeussinger, F. B., Renner, T. J., Fallgatter, A. J., … Ehlis, A.-C. (2017). Near-infrared spectroscopy-based frontal lobe neurofeedback integrated in virtual reality modulates brain and behavior in highly impulsive adults. Frontiers in Human Neuroscience, 11, 425. https://doi.org/10.3389/fnhum.2017.00425",
        "Hurt, E., Arnold, L. E., & Lofthouse, N. (2014). Quantitative EEG neurofeedback for the treatment of pediatric attention-deficit/hyperactivity disorder, autism spectrum disorders, learning disorders, and epilepsy. Child and Adolescent Psychiatric Clinics of North America, 23(3), 465–486. https://doi.org/10.1016/j.chc.2014.02.001",
        "Janssen, T. W. P., Bink, M., Geladé, K., van Mourik, R., Maras, A., & Oosterlaan, J. (2016). A randomized controlled trial into the effects of neurofeedback, methylphenidate, and physical activity on EEG power spectra in children with ADHD. Journal of Child Psychology and Psychiatry, and Allied Disciplines, 57(5), 633–644. https://doi.org/10.1111/jcpp.12517",
        "Janssen, T. W. P., Bink, M., Weeda, W. D., Geladé, K., van Mourik, R., Maras, A., & Oosterlaan, J. (2017). Learning curves of theta/beta neurofeedback in children with ADHD. European Child & Adolescent Psychiatry, 26(5), 573–582. https://doi.org/10.1007/s00787-016-0920-8",
        "Janssen, T. W. P., Bink, M., Geladé, K., van Mourik, R., Maras, A., & Oosterlaan, J. (2016). A randomized controlled trial investigating the effects of neurofeedback, methylphenidate, and physical activity on event-related potentials in children with attention-deficit/hyperactivity disorder. Journal of Child and Adolescent Psychopharmacology, 26(4), 344–353. https://doi.org/10.1089/cap.2015.0144",
        "Johnstone, J., Gunkelman, J., & Lunt, J. (2005). Clinical database development: Characterization of EEG phenotypes. Clinical EEG and Neuroscience, 36(2), 99–107. https://doi.org/10.1177/155005940503600209",
        "Johnstone, S. J., Roodenrys, S. J., Johnson, K., Bonfield, R., & Bennett, S. J. (2017). Game-based combined cognitive and neurofeedback training using Focus Pocus reduces symptom severity in children with diagnosed AD/HD and subclinical AD/HD. International Journal of Psychophysiology: Official Journal of the International Organization of Psychophysiology, 116, 32–44. https://doi.org/10.1016/j.ijpsycho.2017.02.015",
        "Kaiser, D. A., & Othmer, S. (2000). Effect of neurofeedback on variables of attention in a large multi-center trial. Journal of Neurotherapy, 4(1), 5–15. https://doi.org/10.1300/J184v04n01_02",
        "Karimu, R. Y., Azadi, S., & Keshavarzi, P. (2018). The ADHD effect on the actions obtained from the EEG signals. Biocybernetics and Biomedical Engineering. https://doi.org/10.1016/j.bbe.2018.02.007",
        "Koberda, J. L., Koberda, P., Moses, A., Winslow, J., Bienkiewicz, A., Koberda, L. (2014). Z-score LORETA neurofeedback as a potential therapy for ADHD. Biofeedback, 42(2), 74-81. doi: 10.5298/1081-5937-42.2.05",
        "Kropotov, J. D., Grin-Yatsenko, V. A., Ponomarev, V. A., Chutko, L. S., Yakovenko, E. A., & Nikishena, I. S. (2005). ERPs correlates of EEG relative beta training in ADHD children. International Journal of Psychophysiology: Official Journal of the International Organization of Psychophysiology, 55(1), 23–34. https://doi.org/10.1016/j.ijpsycho.2004.05.011",
        "Kubik, A., Kubik, P., Stanios, M., & Kraj, B. (2016). [Clinical and neurophysiological data of neurofeedback therapy in children with ADHD]. Przeglad Lekarski, 73(3), 148–151.",
        "Kübler, A., Neumann, N., Wilhelm, B., Hinterberger, T., & Birbaumer, N. (2004). Predictability of brain-computer communication. Journal of Psychophysiology, 18(2/3), 121–129. https://doi.org/10.1027/0269-8803.18.23.121",
        "La Marca, J. P. ., Jeff. LaMarca@shu. ed. (2018). Historical overview of attention deficit-hyperactivity disorder and neurofeedback: Implications for academic achievement, assessment, and intervention in schools. Contemporary School Psychology (Springer Science & Business Media B.V.), 22(1), 1–17. https://doi.org/10.1007/s40688-017-0155-9",
        "La Marca, J. P., Cruz, D., Fandino, J., Cacciaguerra, F. R., Fresco, J. J., & Guerra, A. T. (2018). Evaluation of artifact-corrected electroencephalographic (EEG) training: a pilot study. Journal of Neural Transmission (Vienna, Austria: 1996). https://doi.org/10.1007/s00702-018-1877-1",
        "La Vaque, T. J., Hammond, D. C., Trudeau, D., Monastra, V., Perry, J., Lehrer, P., … Sherman, R. (2002). Template for developing guidelines for the evaluation of the clinical efficacy of psychophysiological interventions. Applied Psychophysiology and Biofeedback, 27(4), 273–281. https://doi.org/10.1023/A:1021061318355",
        "Lansbergen, M. M., van Dongen-Boomsma, M., Buitelaar, J. K., & Slaats-Willemse, D. (2011). ADHD and EEG-neurofeedback: A double-blind randomized placebo-controlled feasibility study. Journal of Neural Transmission (Vienna, Austria: 1996), 118(2), 275–284. https://doi.org/10.1007/s00702-010-0524-2",
        "Lee, E.-J., & Jung, C.-H. (2017). Additive effects of neurofeedback on the treatment of ADHD: A randomized controlled study. Asian Journal of Psychiatry, 25, 16–21. https://doi.org/10.1016/j.ajp.2016.09.002",
        "Lee, G. J., & Suhr, J. A. (2018). Expectancy effects on self-reported attention-deficit/hyperactivity disorder symptoms in simulated neurofeedback: A pilot study. Archives of Clinical Neuropsychology: The Official Journal of the National Academy of Neuropsychologists. https://doi.org/10.1093/arclin/acy026",
        "Lee, N., Park, S., & Kim, J. (2017). Hippotherapy and neurofeedback training effect on the brain function and serum brain-derived neurotrophic factor level changes in children with attention-deficit or/and hyperactivity disorder. Journal of Exercise Nutrition & Biochemistry, 21(3), 35–42. https://doi.org/10.20463/jenb.2017.0018",
        "Leins, U., Goth, G., Hinterberger, T., Klinger, C., Rumpf, N., & Strehl, U. (2007). Neurofeedback for children with ADHD: a comparison of SCP and Theta/Beta protocols. Applied Psychophysiology and Biofeedback, 32(2), 73–88. https://doi.org/10.1007/s10484-007-9031-0",
        "Lévesque, J., Beauregard, M., & Mensour, B. (2006). Effect of neurofeedback training on the neural substrates of selective attention in children with attention-deficit/hyperactivity disorder: a functional magnetic resonance imaging study. Neuroscience Letters, 394(3), 216–221. https://doi.org/10.1016/j.neulet.2005.10.100",
        "Li, L., Yang, L., Zhuo, C., & Wang, Y.-F. (2013). A randomised controlled trial of combined EEG feedback and methylphenidate therapy for the treatment of ADHD. Swiss Medical Weekly, 143, w13838. https://doi.org/10.4414/smw.2013.13838",
        "Liechti, M. D., Maurizio, S., Heinrich, H., Jäncke, L., Meier, L., Steinhausen, H.-C., … Brandeis, D. (2012). First clinical trial of tomographic neurofeedback in attention-deficit/hyperactivity disorder: evaluation of voluntary cortical control. Clinical Neurophysiology: Official Journal of the International Federation of Clinical Neurophysiology, 123(10), 1989–2005. https://doi.org/10.1016/j.clinph.2012.03.016",
        "Linden, M., Habib, T., & Radojevic, V. (1996). A controlled study of the effects of EEG biofeedback on cognition and behavior of children with attention deficit disorder and learning disabilities. Biofeedback and Self-Regulation, 21(1), 35–49. https://doi.org/10.1007/BF02214148",
        "Loo, S. K., & Barkley, R. A. (2005). Clinical utility of EEG in attention deficit hyperactivity disorder. Applied Neuropsychology, 12(2), 64–76. https://doi.org/10.1207/s15324826an1202_2",
        "Lubar, J. F., & Shouse, M. N. (1976). EEG and behavioral changes in a hyperkinetic child concurrent with training of the sensorimotor rhythm (SMR): a preliminary report. Biofeedback and Self-Regulation, 1(3), 293–306.",
        "Lubar, J. F., Swartwood, M. O., Swartwood, J. N., & O’Donnell, P. H. (1995). Evaluation of the effectiveness of EEG neurofeedback training for ADHD in a clinical setting as measured by changes in T.O.V.A. scores, behavioral ratings, and WISC-R performance. Biofeedback and Self-Regulation, 20(1), 83–99.",
        "Marx, A.-M., Ehlis, A.-C., Furdea, A., Holtmann, M., Banaschewski, T., Brandeis, D., … Strehl, U. (2014). Near-infrared spectroscopy (NIRS) neurofeedback as a treatment for children with attention deficit hyperactivity disorder (ADHD)-a pilot study. Frontiers in Human Neuroscience, 8, 1038. https://doi.org/10.3389/fnhum.2014.01038",
        "Marzbani, H., Marateb, H. R., & Mansourian, M. (2016). Neurofeedback: A comprehensive review on system design, methodology and clinical applications. Basic and Clinical Neuroscience, 7(2), 143–158. https://doi.org/10.15412/J.BCN.03070208",
        "Maurizio, S., Liechti, M. D., Brandeis, D., Jäncke, L., & Drechsler, R. (2013). Differential EMG biofeedback for ",
        "children with ADHD: A control method for neurofeedback training with a case illustration. Applied Psychophysiology and Biofeedback, 38(2), 109–119. https://doi.org/10.1007/s10484-013-9213-x",
        "Maurizio, Stefano, Liechti, M. D., Heinrich, H., Jäncke, L., Steinhausen, H.-C., Walitza, S., … Drechsler, R. (2014). Comparing tomographic EEG neurofeedback and EMG biofeedback in children with attention-deficit/hyperactivity disorder. Biological Psychology, 95, 31–44. https://doi.org/10.1016/j.biopsycho.2013.10.008",
        "Mayer, K., Blume, F., Wyckoff, S. N., Brokmeier, L. L., & Strehl, U. (2016). Neurofeedback of slow cortical potentials as a treatment for adults with Attention Deficit-/Hyperactivity Disorder. Clinical Neurophysiology, 127(2), 1374–1386. https://doi.org/10.1016/j.clinph.2015.11.013",
        "Mayer, K., Wyckoff, S. N., & Strehl, U. (2016). Underarousal in adult ADHD: How are peripheral and cortical arousal related? Clinical EEG and Neuroscience, 47(3), 171–179. https://doi.org/10.1177/1550059415577544",
        "Mazaheri, A., Fassbender, C., Coffey-Corina, S., Hartanto, T. A., Schweitzer, J. B., & Mangun, G. R. (2014). Differential oscillatory electroencephalogram between attention-deficit/hyperactivity disorder subtypes and typically developing adolescents. Biological Psychiatry, 76(5), 422–429. https://doi.org/10.1016/j.biopsych.2013.08.023",
        "Meisel, V., Servera, M., Garcia-Banda, G., Cardo, E., & Moreno, I. (2013). Neurofeedback and standard pharmacological intervention in ADHD: A randomized controlled trial with six-month follow-up. Biological Psychology, 94(1), 12–21. https://doi.org/10.1016/j.biopsycho.2013.04.015",
        "Micoulaud-Franchi, J.-A., Salvo, F., Bioulac, S., & Fovet, T. (2016). Neurofeedback in attention-deficit/hyperactivity disorder: Efficacy. Journal of the American Academy of Child & Adolescent Psychiatry, 55(12), 1091–1092. https://doi.org/10.1016/j.jaac.2016.09.493",
        "Minder, F., Zuberer, A., Brandeis, D., & Drechsler, R. (2018). Informant-related effects of neurofeedback and cognitive training in children with ADHD including a waiting control phase: a randomized-controlled trial. European Child & Adolescent Psychiatry. https://doi.org/10.1007/s00787-018-1116-1",
        "Missonnier, P., Hasler, R., Perroud, N., Herrmann, F. R., Millet, P., Richiardi, J., … Baud, P. (2013). EEG anomalies in adult ADHD subjects performing a working memory task. Neuroscience, 241, 135–146. https://doi.org/10.1016/j.neuroscience.2013.03.011",
        "Mohagheghi, A., Amiri, S., Moghaddasi Bonab, N., Chalabianloo, G., Noorazar, S. G., Tabatabaei, S. M., & Farhang, S. (2017). A randomized trial of comparing the efficacy of two neurofeedback protocols for treatment of clinical and cognitive symptoms of ADHD: Theta suppression/beta enhancement and theta suppression/alpha enhancement. BioMed Research International, 1–7. https://doi.org/10.1155/2017/3513281",
        "Monastra, V. J., Monastra, D. M., & George, S. (2002). The effects of stimulant therapy, EEG biofeedback, and parenting style on the primary symptoms of attention-deficit/hyperactivity disorder. Applied Psychophysiology and Biofeedback, 27(4), 231–249. https://doi.org/10.1023/A:1021018700609",
        "Moreno-García, I., Meneres-Sancho, S., Camacho-Vara de Rey, C., & Servera, M. (2017). A randomized controlled trial to examine the posttreatment efficacy of neurofeedback, behavior therapy, and pharmacology on ADHD measures. Journal of Attention Disorders, 1087054717693371. https://doi.org/10.1177/1087054717693371",
        "Nooner, K. B., Leaberry, K. D., Keith, J. R., & Ogle, R. L. (2017). Clinic outcome assessment of a brief course neurofeedback for childhood ADHD symptoms. The Journal of Behavioral Health Services & Research, 44(3), 506–514. https://doi.org/10.1007/s11414-016-9511-1",
        "Ogrim, G., & Hestad, K. A. (2013). Effects of neurofeedback versus stimulant medication in attention-deficit/hyperactivity disorder: A randomized pilot study. Journal of Child and Adolescent Psychopharmacology, 23(7), 448–457. https://doi.org/10.1089/cap.2012.0090",
        "Okumura, Y., Kita, Y., Omori, M., Suzuki, K., Yasumura, A., Fukuda, A., & Inagaki, M. (2017). Predictive factors of success in neurofeedback training for children with ADHD. Developmental Neurorehabilitation, 1–10. https://doi.org/10.1080/17518423.2017.1326183",
        "Perreau-Linck, E., Lessard, N., Lévesque, J., & Beauregard, M. (n.d.). Effects of neurofeedback training on inhibitory capacities in ADHD children: A single-blind, randomized, placebo-controlled study. Journal of Neurotherapy, 14(3), 229–242.",
        "Rief, W. (2017). Neurofeedback in adults with attention-deficit hyperactivity disorder. The Lancet. Psychiatry, 4(9), 650–651. https://doi.org/10.1016/S2215-0366(17)30314-0",
        "Rossiter, D. T. R., & La Vaque, T. J. (1995). A comparison of EEG biofeedback and psychostimulants in treating attention deficit/hyperactivity disorders. Journal of Neurotherapy, 1(1), 48–59. https://doi.org/10.1300/J184v01n01_07",
        "Rossiter, T. (2004). The effectiveness of neurofeedback and stimulant drugs in treating AD/HD: Part II. Replication. Applied Psychophysiology and Biofeedback, 29(4), 233–243.",
        "Rossiter, T. R., & Vaque, T. J. L. (2016). A comparison of EEG biofeedback and psychostimulants in treating attention deficit/hyperactivity disorders. Journal of Neurotherapy, 1(1), 48–59. https://doi.org/10.1300/J184v01n01_07",
        "Russell-Chapin, L., Kemmerly, T., Liu, W.-C., Zagardo, M. T., Chapin, T., Dailey, D., & Dinh, D. (2013). The effects of neurofeedback in the default mode network: Pilot study results of medicated children with ADHD. Journal of Neurotherapy, 17(1), 35–42. https://doi.org/10.1080/10874208.2013.759017",
        "Schönenberg, M., Wiedemann, E., Schneidt, A., Scheeff, J., Logemann, A., Keune, P. M., & Hautzinger, M. (2017). Neurofeedback, sham neurofeedback, and cognitive-behavioural group therapy in adults with attention-deficit hyperactivity disorder: A triple-blind, randomised, controlled trial. The Lancet. Psychiatry, 4(9), 673–684. https://doi.org/10.1016/S2215-0366(17)30291-2",
        "Shephard, E., Jackson, G. M., & Groom, M. J. (2016). Electrophysiological correlates of reinforcement learning in young people with Tourette syndrome with and without co-occurring ADHD symptoms. International Journal of Developmental Neuroscience: The Official Journal of the International Society for Developmental Neuroscience, 51, 17–27. https://doi.org/10.1016/j.ijdevneu.2016.04.005",
        "Shin, M.-S., Jeon, H., Kim, M., Hwang, T., Oh, S. J., Hwangbo, M., & Kim, K. J. (2016). Effects of smart-tablet-based neurofeedback training on cognitive function in children with attention problems. Journal of Child Neurology, 31(6), 750–760. https://doi.org/10.1177/0883073815620677",
        "Shouse, M. N., & Lubar, J. F. (1979). Operant conditioning of EEG rhythms and ritalin in the treatment of hyperkinesis. Biofeedback and Self-Regulation, 4(4), 299–312.",
        "Simkin, D. R., Arnold, L. E., & Lubar, J. (2016). Neurofeedback in attention-deficit/hyperactivity disorder: Evaluation difficulties. Journal of the American Academy of Child & Adolescent Psychiatry, 55(12), 1090–1091. https://doi.org/10.1016/j.jaac.2016.09.494",
        "Simkin, D. R., Thatcher, R. W., & Lubar, J. (2014). Quantitative EEG and neurofeedback in children and adolescents: Anxiety disorders, depressive disorders, comorbid addiction and attention-deficit/hyperactivity disorder, and brain injury. Child and Adolescent Psychiatric Clinics of North America, 23(3), 427–464. https://doi.org/10.1016/j.chc.2014.03.001",
        "Sitaram, R., Ros, T., Stoeckel, L., Haller, S., Scharnowski, F., Lewis-Peacock, J., … Sulzer, J. (2017). Closed-loop brain training: the science of neurofeedback. Nature Reviews. Neuroscience, 18(2), 86–100. https://doi.org/10.1038/nrn.2016.164",
        "Sollie, H., Larsson, B., & Mørch, W.-T. (2013). Comparison of mother, father, and teacher reports of ADHD core symptoms in a sample of child psychiatric outpatients. Journal of Attention Disorders, 17(8), 699–710. https://doi.org/10.1177/1087054711436010",
        "Sonuga-Barke, E. J. S., Brandeis, D., Cortese, S., Daley, D., Ferrin, M., Holtmann, M., … European ADHD Guidelines Group. (2013). Nonpharmacological interventions for ADHD: Systematic review and meta-analyses of randomized controlled trials of dietary and psychological treatments. The American Journal of Psychiatry, 170(3), 275–289. https://doi.org/10.1176/appi.ajp.2012.12070991",
        "Steiner, N. J., Frenette, E. C., Rene, K. M., Brennan, R. T., & Perrin, E. C. (2014a). In-school neurofeedback training for ADHD: Sustained improvements from a randomized control trial. Pediatrics, 133(3), 483–492. https://doi.org/10.1542/peds.2013-2059",
        "Steiner, N. J., Frenette, E. C., Rene, K. M., Brennan, R. T., & Perrin, E. C. (2014b). Neurofeedback and cognitive attention training for children with attention-deficit hyperactivity disorder in schools. Journal of Developmental and Behavioral Pediatrics: JDBP, 35(1), 18–27. https://doi.org/10.1097/DBP.0000000000000009",
        "Steiner, N. J., Sheldrick, R. C., Gotthelf, D., & Perrin, E. C. (2011). Computer-based attention training in the schools for children with attention deficit/hyperactivity disorder: A preliminary trial. Clinical Pediatrics, 50(7), 615–622. https://doi.org/10.1177/0009922810397887",
        "Strehl, U. (2014). What learning theories can teach us in designing neurofeedback treatments. Frontiers in Human Neuroscience, 8. https://doi.org/10.3389/fnhum.2014.00894",
        "Strehl, U., Leins, U., Goth, G., Klinger, C., Hinterberger, T., & Birbaumer, N. (2006). Self-regulation of slow cortical potentials: A new treatment for children with attention-deficit/hyperactivity disorder. Pediatrics, 118(5), e1530-1540. https://doi.org/10.1542/peds.2005-2478",
        "Takahashi, J., Yasumura, A., Nakagawa, E., & Inagaki, M. (2014). Changes in negative and positive EEG shifts during slow cortical potential training in children with attention-deficit/hyperactivity disorder: A preliminary investigation. Neuroreport, 25(8), 618–624. https://doi.org/10.1097/WNR.0000000000000156",
        "Teruel, M. A., Navarro, E., Romero, D., García, M., Fernández-Caballero, A., & González, P. (2017). An innovative tool to create neurofeedback games for ADHD treatment. In Natural and Artificial Computation for Biomedicine and Neuroscience (pp. 183–192). Springer, Cham. https://doi.org/10.1007/978-3-319-59740-9_18",
        "Thibault, R. T., Lifshitz, M., & Raz, A. (2016). The self-regulating brain and neurofeedback: Experimental science and clinical promise. Cortex, 74, 247–261. https://doi.org/10.1016/j.cortex.2015.10.024",
        "Thomas, B. L., & Viljoen, M. (2016). EEG brain wave activity at rest and during evoked attention in children with attention-deficit/hyperactivity disorder and effects of methylphenidate. Neuropsychobiology, 73(1), 16–22. https://doi.org/10.1159/000441523",
        "Thompson, L., & Thompson, M. (1998). Neurofeedback combined with training in metacognitive strategies: Effectiveness in students with ADD. Applied Psychophysiology and Biofeedback, 23(4), 243–263.",
        "Thompson, Lynda, & Thompson, M. (2005). Neurofeedback intervention for adults with ADHD. Journal of Adult Development, 12(2–3), 123–130. https://doi.org/10.1007/s10804-005-7028-6",
        "van Dongen-Boomsma, M., Vollebregt, M. A., Slaats-Willemse, D., & Buitelaar, J. K. (2013). A randomized placebo-controlled trial of electroencephalographic (EEG) neurofeedback in children with attention-deficit/hyperactivity disorder. The Journal of Clinical Psychiatry, 74(8), 821–827. https://doi.org/10.4088/JCP.12m08321",
        "Van Doren, J., Heinrich, H., Bezold, M., Reuter, N., Kratz, O., Horndasch, S., … Studer, P. (2017). Theta/beta neurofeedback in children with ADHD: Feasibility of a short-term setting and plasticity effects. International Journal of Psychophysiology: Official Journal of the International Organization of Psychophysiology, 112, 80–88. https://doi.org/10.1016/j.ijpsycho.2016.11.004",
        "Wang, L.-J., Chen, C.-K., & Huang, Y.-S. (2013). Changes in behaviour symptoms of patients with attention deficit/hyperactivity disorder during treatment: Observation from different informants. Psychiatry Investigation, 10(1), 1–7. https://doi.org/10.4306/pi.2013.10.1.1",
        "Wangler, S., Gevensleben, H., Albrecht, B., Studer, P., Rothenberger, A., Moll, G. H., & Heinrich, H. (2011). Neurofeedback in children with ADHD: Specific event-related potential findings of a randomized controlled trial. Clinical Neurophysiology, 122(5), 942–950. https://doi.org/10.1016/j.clinph.2010.06.036",
        "Willcutt, E. G., Hartung, C. M., Lahey, B. B., Loney, J., & Pelham, W. E. (1999). Utility of behavior ratings by examiners during assessments of preschool children with attention-deficit/hyperactivity disorder. Journal of Abnormal Child Psychology, 27(6), 463–472.",
        "Witte, M., Kober, S. E., Ninaus, M., Neuper, C., & Wood, G. (2013). Control beliefs can predict the ability to up-regulate sensorimotor rhythm during neurofeedback training. Frontiers in Human Neuroscience, 7, 478. https://doi.org/10.3389/fnhum.2013.00478",
        "Xiong, Z., Shi, S., & Xu, H. (2005). A controlled study of the effectiveness of EEG biofeedback training on-children with attention deficit hyperactivity disorder. Journal of Huazhong University of Science and Technology. Medical Sciences = Hua Zhong Ke Ji Da Xue Xue Bao. Yi Xue Ying De Wen Ban = Huazhong Keji Daxue Xuebao. Yixue Yingdewen Ban, 25(3), 368–370.",
        "Zilverstand, A., Sorger, B., Slaats-Willemse, D., Kan, C. C., Goebel, R., & Buitelaar, J. K. (2017). fMRI neurofeedback training for increasing anterior cingulate cortex activation in adult attention deficit hyperactivity disorder: An exploratory randomized, single-blinded study. PloS One, 12(1), e0170795. https://doi.org/10.1371/journal.pone.0170795",
        "Zuberer, A., Brandeis, D., & Drechsler, R. (2015). Are treatment effects of neurofeedback training in children with ADHD related to the successful regulation of brain activity? A review on the learning of regulation of brain activity and a contribution to the discussion on specificity. Frontiers in Human Neuroscience, 9, 135. https://doi.org/10.3389/fnhum.2015.00135",
      ],
    },
    {
      title: "ADDICTION/SUBSTANCE ABUSE",
      articles: [
        "Burkett, V. S., Cummins, J. M., Dickson, R. M., & Skolnick, M. (2005). An open clinical trial utilizing real-time EEG operant conditioning as an adjunctive therapy in the treatment of crack cocaine dependence. Journal of Neurotherapy, 9(2), 27–48.",
        "Callaway, T.G, Bodenhamer-Davis, E. (2008). Long-term follow-up of a clinical replication of the Peniston Protocol for chemical dependency. Journal of Neurotherapy, 12(4), 243–259.",
        "deBeus, R. J. (2007). Quantitative electroencephalography-guided versus Scott/Peniston neurofeedback with substance abuse outpatients: A pilot study. Biofeedback, 35(4), 146–151.",
        "Fahrion, S. L., Walters, E. D., Coyne, L., & Allen, T. (1992). Alterations in EEG amplitude, personality factors and brain electrical mapping after alpha theta brainwave training: A controlled case study of an alcoholic in recovery. Alcoholism: Clinical & Experimental Research, 16, 547–552.",
        "Fahrion, S. L. (1995). Human potential and personal transformation. Subtle Energies, 6, 55–88.",
        "Goldberg, R. J., et al. (1976). Alpha conditioning as an adjunct treatment for drug dependence: Part I. International Journal of Addiction, 11, 1085–1089.",
        "Goldberg, R. J., et al. (1977). Alpha conditioning as an adjunct treatment for drug dependence: Part II. International Journal of Addiction, 12, 195–204.",
        "Horrell, T., El-Baz, A., Baruth, J., Tasman, A., Sokhadze, G., Stewart, C., Sokhadze, E. (2010). Neurofeedback effects on evoked and induced EEG gamma band reactivity to drug-related cues in cocaine addiction. Journal of Neurotherapy, 14(3), 195–216.",
        "Kelly, M. J. (1997). Native Americans, neurofeedback, and substance abuse theory: Three year outcome of alpha/theta neurofeedback training in the treatment of problem drinking among Dine= (Navajo) people. Journal of Neurotherapy, 2(3), 24–60.",
        "Lamontague, Y., Hand, I., Annable, L., et al. (1975). Physiological and psychological effects of alpha and EMG feedback training with college drug users: A pilot study. Canadian Psychiatric Association Journal, 20, 337–349.",
        "Passini, F., Watson, C. G., Dehnel, L., Herder, J., & Watkins, B. (1977). Alpha wave biofeedback training therapy in alcoholics. Journal of Clinical Psychology, 33(1), 292299.",
        "Peniston, E. G., & Kulkosky, P. J. (1989). Alpha-theta brainwave training and beta-endorphin levels in alcoholics. Alcohol: Clinical & Experimental Research, 13(2), 271279.",
        "Peniston, E. G., & Kulkosky, P. J. (1991). Alcoholic personality and alpha-theta brainwave training. Medical Psychotherapy, 2, 37–55.",
        "Peniston, E. G., Marrinan, D. A., Deming, W. A., & Kulkosky, P. J. (1993). EEG alpha-theta brainwave synchronization in Vietnam theater veterans with combat-related post-traumatic stress disorder and alcohol abuse. Advances in Medical Psychotherapy, 6, 37–50.",
        "Saxby, E., & Peniston, E. G. (1995). Alpha-theta brainwave neurofeedback training: An effective treatment for male and female alcoholics with depressive symptoms. Journal of Clinical Psychology, 51(5), 685–693.",
        "Schneider, F., Elbert, T., Heimann, H., Welker, A., Stetter, F., Mattes, R., Birbaumer, N., & Mann, K. (1993). Self-regulation of slow cortical potentials in psychiatric patients: Alcohol dependency. Biofeedback & Self-Regulation, 18, 23–32.",
        "Scott, W., & Kaiser, D. (1998). Augmenting chemical dependency treatment with neurofeedback training. Journal of Neurotherapy, 3(1), 66.",
        "Scott, W. C., Kaiser, D., Othmer, S., Sideroff, S. I. (2005) Effects of an EEG Biofeedback Protocol on a Mixed Substance Abusing Population. American Journal of Drug and Alcohol Abuse, 31(3), 455-469",
        "Sokhadze, E., Stewart, C., Hollifield, M., Tasman, A. (2008). Event-related potential study of executive dysfunctions in a speeded reaction task in cocaine addiction. Journal of Neurotherapy, 12(4), 185–204.",
        "Sokhadze, E., Singh, S., Stewart, C., Hollifield, M., El-Baz, A., Tasman, A. (2008). Attentional bias to drug-and stress-related pictorial cues in cocaine addiction comorbid with Posttraumatic Stress Disorder. Journal of Neurotherapy, 12(4), 205–225.",
        "Sokhadze, E. M., Cannon R. L., & Trudeau D. L. (2008) EEG biofeedback as a treatment for Substance Use Disorders: review, rating of efficacy, and recommendations for further research. Journal of Neurotherapy, 12(1), 5–43.",
        "Sokhadze, T. M., Stewart, C. M., & Hollifield, M. (2007). Integrating cognitive neuroscience and cognitive behavioral treatment with neurofeedback therapy in drug addiction comorbid with posttraumatic stress disorder: A conceptual review. Journal of Neurotherapy, 11(2), 13–44.",
        "Sokhadze, T. M., Cannon, R. L., & Trudeau, D. L. (2008). EEG biofeedback as a treatment for substance use disorders: Review, rating of efficacy, and recommendations for further research. Applied Psychophysiology & Biofeedback, 33(1), 1–28.",
        "Trudeau, D. L. (2008) Brainwave biofeedback for addictive disorder. Journal of Neurotherapy, 12(4), 181–183.",
        "Trudeau, D. L. (2005). Applicability of brain wave biofeedback to substance use disorder in adolescents. Child & Adolescent Psychiatric Clinics of North America, 14(1), 125136.",
        "Trudeau, D. L. (2000). The treatment of addictive disorders by brain wave biofeedback: A review and suggestions for future research. Clinical Electroencephalography, 31(1), 1322.",
        "Watson, C. G., Herder, J., & Passini, F. T. (1978). Alpha biofeedback therapy in alcoholics: An 18-month follow-up. Journal of Clinical Psychology, 34(3), 765–769.",
      ],
    },
    {
      title: "ANXIETY/PANIC DISORDERS",
      articles: [
        "Burti, L., & Siciliani, O. (1983). Increase in alpha-rhythm in anxious subjects using biofeedback: A preliminary study. Psichiatria Generale e dell Eta Evolutiva, 21(2–4), 79–97.",
        "Chisholm, R. C., DeGood, D. E., & Hartz, M. A. (1977). Effects of alpha feedback training on occipital EEG, heart rate, and experiential reactivity to a laboratory stressor. Psychophysiology, 14(2), 157–163.",
        "Dreis, S. M., Gouger, A. M., Perez, E. G., Ruso, G. M., Fitzsimmons, M. A., Jones, M. S. (2015) Using Neurofeedback to Lower Anxiety Symptoms Using Individualized qEEG Protocols: A Pilot Study. NeuroRegulation 2(3), 137-148.",
        "Garrett, B. L., & Silver, M. P. (1976). The use of EMG and alpha biofeedback to relieve test anxiety in college students. Chapter in I. Wickramasekera (Ed.), Biofeedback, Behavior Therapy, and Hypnosis. Chicago: Nelson–Hall.",
        "Hammond, D. C. (2005). Neurofeedback with anxiety and affective disorders. Child & Adolescent Psychiatric Clinics of North America, 14(1), 105–123.",
        "Hardt, J. V., & Kamiya, J. (1978). Anxiety change through electroencephalographic alpha feedback seen only in high anxiety subjects. Science, 201, 79–81.",
        "Holmes, D. S., Burish, T. G., & Frost, R. O. (1980). Effects of instructions and biofeedback in EEG-alpha production and the effects of EEG-alpha biofeedback training for controlled arousal in a subsequent stressful situation. Journal of Research in Personality, 14(2), 212–223.",
        "Huang-Storms, L., Bodenhamer-Davis, E., Davis, R., & Dunn, J. (2006). QEEG-guided neurofeedback for children with histories of abuse and neglect: Neurodevelopmental rationale and pilot study. Journal of Neurotherapy, 10(4), 3–16.",
        "Keller, I. (2001). Neurofeedback therapy of attention deficits in patients with traumatic brain injury. Journal of Neurotherapy, 5(1-2), 19–32.",
        "Kerson, C., Sherman, R.A., Kozlowski, G.P. (2009). Alpha suppression and symmetry training for generalized anxiety symptoms. Journal of Neurotherapy, 13(3), 146–155.",
        "Kirschbaum, J., & Gisti, E. (1973). Correlations of alpha percentage in EEG, alpha feedback, anxiety scores from MAS and MMQ. Archives fur Psychologie, 125(4), 263273.",
        "Lu, Y., Wang, C., Su, L., Ma, Z., Li, S., & Fan, Y. (2017). Effects of neurofeedback on panic disorder patients’ anxiety. NeuroQuantology, 15(3). https://doi.org/10.14704/nq.2017.15.3.1083",
        "McKnight, J. T., & Fehmi, L. G. (2001). Attention and neurofeedback synchrony training: Clinical results and their significance. Journal of Neurotherapy, 5(1–2), 45–62.",
        "Mennella R, Patron E, Palomba D. Frontal alpha asymmetry neurofeedback for the reduction of negative affect and anxiety. Behav Res Ther. 2017 May;92:32-40.  doi: 10.1016/j.brat.2017.02.002. Epub 2017 Feb 20.",
        "Moore, N. C. (2000). A review of EEG biofeedback treatment of anxiety disorders. Clinical Electroencephalography, 31(1), 1–6.",
        "Norris, S. L., Lee, C-T., Burshteyn, D., & Cea-Aravena, J. (2001). The effects of performance enhancement training on hypertension, human attention, stress, and brain wave patterns: A case study. Journal of Neurotherapy, 4(3), 29–44.",
        "Plotkin, W. B., & Rice, K. M. (1981). Biofeedback as a placebo: Anxiety reduction facilitated by training in either suppression or enhancement of alpha brainwaves. Journal of Consulting & Clinical Psychology, 49, 590–596.",
        "Rice, K. M., Blanchard, E. B., & Purcell, M. (1993). Biofeedback treatments of generalized anxiety disorder: Preliminary results. Biofeedback & Self-Regulation, 18, 93–105.",
        "Sattlberger, E., & Thomas, J. E. (2000). Treatment of anxiety disorder with slow-wave suppression EEG feedback: A case study. Biofeedback, 28(4), 17–19.",
        "Thomas, J. E., & Sattlberger, B. A. (1997). Treatment of chronic anxiety disorder with neurotherapy: A case study. Journal of Neurotherapy, 2(2), 14–19.",
        "Valdez, M. (1988). A program of stress management in a college setting. Psychotherapy in Private Practice, 6(2), 43–54.",
        "Vanathy, S., Sharma, P. S. V. N., & Kumar, K. B. (1998). The efficacy of alpha and theta neurofeedback training in treatment of generalized anxiety disorder. Indian Journal of Clinical Psychology, 25(2), 136–143.",
        "White, E. K., Groeneveld, K. M., Tittle, R. K., Bolhuis, N. A., Martin, R. E., Royer, T. G., Fotuhi, M. (2017) Combined Neurofeedback and Hearth Rate Variability Training for Individuals with Symptoms of Anxiety and Depression: A Retrospective Study. NeuroRegulation 4(1) 37-55.",
      ],
    },
    {
      title: "AUTISM SPECTRUM/ASPERGERS",
      articles: [
        "Baruth, J., Casanova, M., El-Baz, A., Horrell, T., Mathai, G., Sears, L., Sokhadze, E. (2010). Low-frequency repetitive transcranial magnetic stimulation modulates evoked gamma frequency oscillations in autism spectrum disorder. Journal of Neurotherapy, 14(3), 179–194.",
        "Coben, R., Mohammad-Rezazadeh, I., Cannon, R. (2014). Using quantitative and analytic EEG methods in the understanding of connectivity in autism spectrum disorders: a theory of mixed over- and under-connectivity. Frontiers in Human Neuroscience 8:45. doi: 10.3389/fnhum.2014.00045.",
        "Coben, R., & Myers, T. E. (2010). The relative efficacy of connectivity guided and symptom based EEG biofeedback for autistic disorders. Applied Psychophysiology & Biofeedback, 35(1), 13–23.",
        "Coben, R., & Pudolsky, I. (2007). Assessment-guided neurofeedback for autistic spectrum disorder. Journal of Neurotherapy, 11(1), 5–23.",
        "Coben, R. (2007). Connectivity-guided neurofeedback for autistic spectrum disorder. Biofeedback, 35(4), 131–135.",
        "Coben, R. Sherlin, L., Hudspeth, W. J., McKeon, K., & Ricca, R. (2014) Connectivity-Guided EEG Biofeedback for Autism Spectrum Disorder: Evidence of Neurophysiological Changes. Journal of NeuroRegulation, 1(2) 109-130.",
        "Drysdale, M,T,B., Martinez, Y, J., Thompson, L. (2012). The effects of humorous literature on emotion: A pilot project comparing children with asperger’s syndrome before and after neurofeedback training and controls. Journal of Neurofeedback.",
        "Friedrich, E., Sivanathan, A., Lim, T., Suttie, N., Louchart, S., Pillen, S., Pineda, J. (2015). An effective neurofeedback intervention to improve social interactions in children with autism spectrum disorder. Journal of Autism & Developmental Disorders, 45(12), 4084-4100. doi: 10.1007/s10803-015-2523-5",
        "Frye, R., Rossignol, D., Casanova, M., Martin, V., Brown, G., Edelson, S., Coben, R., Lewine, J., Slattery, J., Lau, C., Hardy, P., Fatemi, S., Folsom, T., MacFabe, D., Adams, J. (2013). A review of traditional and novel treatments for seizures in autism spectrum disorder. Findings from a systematic review and expert panel. Public Health 1:31. doi: 10.3389/fpubh.00031",
        "Goodman, M. S., Castro, N., Sloan, M., Sharma, R., Widdowson, M., Herrera, E., Pineda, J. A. (2018). A Neurovisceral Approach to Autism: Targeting Self-Regulation and Core Symptoms Using Neurofeedback and Biofeedback. NeuroRegulation 5(1), 9-29.",
        "Jarusiewicz, G. (2007). Use of neurofeedback with autistic spectrum disorders. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, pp. 321–339.",
        "Jarusiewicz, B. (2002). Efficacy of neurofeedback for children in the autistic spectrum: A pilot study. Journal of Neurotherapy, 6(4), 39–49.",
        "Knezevic, B., Thompson, L., & Thompson, M. (2010). Pilot project to ascertain the utility of Tower of London Test to assess outcomes of neurofeedback in clients with Asperger’s Syndrome. Journal of Neurotherapy, 14(3), 3–19.",
        "Kouijzer, M. E. UJ., de Moor, J. M. H., Gerrits, B. J. L., Buitelaar, J. K., & van Schie, H. T. (2009). Long-term effects of neurofeedback treatment in autism. Research in Autism Spectrum Disorders, 3(2), 496–501.",
        "Pineda J. A., Brang D, Hecht E, Edwards L, Carey S, Bacon M, Futagaki C, Suk D, Tom J, Birnbaum C, Rork A.(2008). Positive behavioral and electrophysiological changes following neurofeedback training in children with autism. Research in Autism Spectrum Disorders, 2, 557–581.",
        "Scolnick, B. (2005). Effects of electroencephalogram biofeedback with Asperger’s syndrome. International Journal of Rehabilitation Research, 28(2), 159–163.",
        "Sichel, A. G., Fehmi, L. G., & Goldstein, D. M. (1995). Positive outcome with neurofeedback treatment of a case of mild autism. Journal of Neurotherapy, 1(1), 6064.",
        "Sokhadze, E., Baruth, J., El-Baz, A., Horrell, T., Sokhadze, G., Carroll, T., Tasman, A., Sears, L., Casanova, M. (2010). Impaired error monitoring and correction function in Autism. Journal of Neurotherapy, 14(2), 79–95.",
        "Sokhadze, E. M.,  Casanova, M. F.,  El-Baz, A. S.,  Farag, H. E.,  Li, X. & Wang, Y. (2016). TMS-based Neuromodulation of Evoked and Induced Gamma Oscillations and Event-related Potentials in Children with Autism.  NeuroRegulation, 3.(3),  101-126.",
        "Sokhadze, G., Casanova, M. F., Kelly, D., Casanova, E., Russell, B., Sokhadze E. M. (2017). Neuromodulation based on rTMS affects behavioral measures and autonomic nervous system activity in children with autism. NeuroRegulation 4(2), 65-78.",
        "Sokhadze, E., El-Baz, A., Tasman, A., Sears, L., Wang, Y., Lamina, E., Casanova, M. (2014). Neuromodulation integrating rTMS and neurofeedback for the treatment of autism spectrum disorder: an exploratory study.  Applied Psychophysiology & Biofeedback, 39(3-4), 237-257. doi: 10.1007/s10484-014-9264-7",
        "Steiner, N., Frenette, E., Hynes, C., Pisarik, E., Tomasetti, K., Perrin, E., Rene, K. (2014). A pilot feasibility study of neurofeedback for children with autism. Applied Psychophysiology & Biofeedback, 39(2), 99-107. doi: 10.1007/s10484-014-9241-1",
        "Thompson, M., Thompson, L., & Reid, A. (2010). Functional Neuroanatomy and the Rationale for Using EEG Biofeedback for Clients with Asperger’s Syndrome. Journal of Applied Psychophysiology and Biofeedback, 35(1), 39-61.",
        "Wang, Y., Sokhadze, E. M., El-Baz, A. S., Li, X., Sears, L., Casanova, M. F., Tasman, A. (2015). Relative power of specific EEG bands and their ratios during neurofeedback training in children with Autism Spectrum Disorder. Frontiers in Human Neuroscience, 9, 723.",
        "",
        "",
        "CHRONIC FATIGUE/FIBROMYALGIA",
        "",
        "Donaldson, C. C. S., Sella, G. E., & Mueller, H. H. (1998). Fibromyalgia: A retrospective study of 252 consecutive referrals. Canadian Journal of Clinical Medicine, 5 (6), 116127.",
        "Hammond, D. C. (2001). Treatment of chronic fatigue with neurofeedback and self-hypnosis. NeuroRehabilitation, 16, 295–300.",
        "James, L. C., & Folen, R. A. (1996). EEG biofeedback as a treatment for chronic fatigue syndrome: A controlled case report. Behavioral Medicine, 22(2), 77–81.",
        "Kayrian, S., Dursun, E., Ermutlu, N., Dursun, N., & Karamursel, S. (2007). Neurofeedback in fibromyalgia syndrome. The Journal of the Turkish Society of Algology, 19(3), 47–53.",
        "Mueller, H. H., Donaldson, C. C. S., Nelson, D. V., & Layman, M. (2001). Treatment of fibromyalgia incorporating EEG-driven stimulation: A clinical outcomes study. Journal of Clinical Psychology, 57(7), 933–952.",
        "Tansey, M. A. (1993). Neurofeedback and chronic fatigue syndrome: New findings with respect to diagnosis and treatment. CFIDS Chronicle, 9, 30–32.",
        "Zinn, M. A., Zinn, M. L., Jason, L. A. (2017). Small-World Network Analysis of Cortical Connectviity in Chronic Fatigue Syndrome using Quantitative EEG. NeuroRegulation 4(3-4), 125-137.",
      ],
    },
    {
      title: "COGNITIVE DECLINE/MEMORY",
      articles: [
        "Albert, A. O., Andrasik, F., Moore, J. L., & Dunn, B. R. (1998). Theta/beta training for attention, concentration and memory improvement in the geriatric population. Applied Psychophysiology & Biofeedback, 23(2), 109. Abstract.",
        "Angelakis, E., Stathopoulou, S., Frymiare, J. L., Green, D. L., Lubar, J. F., & Kounios, J. (2007). EEG neurofeedback: A brief overview and an example of peak alpha frequency training for cognitive enhancement in the elderly. Clinical Neuropsychology, 21(1), 110129.",
        "Budzynski, T., Budzynski, H. K., &l Tang, H-Y. (2007). Brain brightening: restoring the aging mind. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, 231–265.",
        "Gomez-Pilar J, Corralejo R, Nicolas-Alonso LF, Álvarez D, Hornero R. Neurofeedback training with a motor imagery-based BCI: neurocognitive improvements and EEG changes in the elderly. Med Biol Eng Comput. 2016 Nov;54(11):1655-1666. Epub 2016 Feb 23.",
        "Koberda, J. L. (2014). Z-score LORETA neurofeedback as a potential therapy in cognitive dysfunction and dementia. Journal of Psychology & Clinical Psychiatry, 1(6). doi: 10.15406/jpcpy.2014.01.00037",
      ],
    },
    {
      title: "COGNITIVE ENHANCEMENT/PERFORMANCE",
      articles: [
        "Albert, A. O., Andrasik, F., Moore, J. L., & Dunn, B. R. (1998). Theta/beta training for attention, concentration and memory improvement in the geriatric population. Applied Psychophysiology & Biofeedback, 23(2), 109. Abstract.",
        "Alhambra, M. A., Fowler, T. P., & Alhambra, A. A. (1995). EEG biofeedback: A new treatment option for ADD/ADHD. Journal of Neurotherapy, 1(2), 39–43.",
        "Arns, M., Kleinnijenhuis, M., Fallahpour, K., & Bretler, R. (2007). Golf performance enhancement and real-life neurofeedback training using personalized event-locked EEG profiles. Journal of Neurotherapy, 11(4), 11–18.",
        "Barabasz, A., & Barabasz, M. (1996). Neurotherapy and alert hypnosis in the treatment of attention deficit disorder. Chapter in S. J. Lynn, I. Kirsch, & J. W. Rhue (Eds.), Casebook of Clinical Hypnosis. Washington, D.C.: American Psychological Association Press, 271–292.",
        "Barabasz, A., & Barabasz, M. (2000). Treating AD/HD with hypnosis and neurotherapy. Child Study Journal, 30(1), 25–42.",
        "Bazanova, O.M., Aftanas, L.I. (2010). Individual EEG alpha activity analysis for enhancement neurofeedback efficiency: Two case studies. Journal of Neurotherapy, 14(3), 244–253.",
        "Beauregard, M., & Levesque, J. (2006). Functional magnetic resonance imaging investigation of the effects of neurofeedback training on the neural bases of selective attention and response inhibition in children with attention-deficit/hyperactivity disorder. Applied Psychophysiology & Biofeedback, 31(1), 3–20.",
        "Becerra J, Fernndez T, Harmony T, Caballero MI, Garcia F, Fernandez-Bouzas A, Santiago-Rodriguez E, Prado-Alcalá RA. (2006) Follow-up study of Learning Disabled children treated with Neurofeedback or placebo. Clinical EEG & Neuroscience, 37 (3), 198–203.",
        "Boyd, W. D., & Campbell, S. E. (1998). EEG biofeedback in the schools: The use of EEG biofeedback to treat ADHD in a school setting. Journal of Neurotherapy, 2(4), 65–71.",
        "Breteler, M. H. M., Arns, M., Peters, S., Giepmans, I., & Verhoeven, L. (2010). Improvements in spelling after QEEG-based neurofeedback in dyslexia: A randomized controlled treatment study. Applied Psychophysiology & Biofeedback, 35(1), 5–11.",
        "Budzynski, T. H. (1996). Brain brightening: Can neurofeedback improve cognitive process? Biofeedback, 24(2), 14–17.",
        "Carmody, D. P., Radvanski, D. C., Wadhwani, S., Sabo, J. J., & Vergara, L. (2001). EEG biofeedback training and attention-deficit/hyperactivity disorder in an elementary school setting. Journal of Neurotherapy, 4(3), 5–27.",
        "Carter, J. L., & Russell, H. L. (1991). Changes in verbal performance IQ discrepancy scores after left hemisphere frequency control training: A pilot report. American Journal of Clinical Biofeedback, 4(1), 66–67",
        "Cunningham, M., & Murphy, P. (1981). The effects of bilateral EEG biofeedback on verbal, visuospatial and creative skills in LD male adolescents. Journal of Learning Disabilities, 14(4), 204–208.",
        "Drechsler R, Straub M, Doehnert M, Heinrich H, Steinhausen H, Brandeis D. (2007). Controlled evaluation of a neurofeedback training of slow cortical potentials in children with ADHD. Behavioral & Brain Functions, 3, 35.",
        "Escolano, C., Navarro-Gil, M., Garcia-Campayo, J., Minguez, J. (2014). The effects of a single",
        "session of upper alpha neurofeedback for cognitive enhancement: a sham-controlled study. Applied Psychophysiology & Biofeedback. 39(3-4), 227-236. doi: 10.1007/s10484-014-9262-9",
        "Egner, T., & Gruzelier, J. H. (2001). Learned self-regulation of EEG frequency Components affects attention and event-related brain potentials in humans. NeuroReport, 12, 4155–4159.",
        "Egner, T., & Gruzelier, J. H. (2004). EEG biofeedback of low beta band components: Frequency-specific effects on variables of attention and event-related brain potentials. Clinical Neurophysiology, 115(1), 131–139.",
        "Fehmi, L. G. (2007). Multichannel EEG phase synchrony training and verbally guided attention training for disorders of attention. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, 301–319.",
        "Fehmi, L. G. (1978). EEG biofeedback, multichannel synchrony training, and attention. Chapter in A. A. Sugarman & R. E. Tarter (Eds.), Expanding Dimensions of Consciousness. New York: Springer.",
        "Fehmi, L. G., & Selzer, F. A. (1980). Biofeedback and attention training. Chapter in S. Boorstein (Ed.), Transpersonal Psychotherapy. Palo Alto: Science and Behavior Books.",
        "Fernandez, T., Herrera, W., Harmony, T., Diaz-Comas, L., Santiago, E., Sanchez, L., Bosch, J., Fernandez-Bouzas, A., Otero, G., Ricardo-Garcell, J., Barraza, C., Aubert, E., Galan, L., & Valdes, P. (2003). EEG and behavioral changes following neurofeedback treatment in learning disabled children. Clinical Electroencephalography, 34(3), 145–150.",
        "Fleischman, M. J., & Othmer, S. (2005). Case study: Improvements in IQ score and maintenance of gains following EEG biofeedback with mildly developmentally delayed twins. Journal of Neurotherapy, 9(4), 35–46.",
        "Foks, M. (2005). Neurofeedback training as an educational intervention in a school setting: How the regulation of arousal states can lead to improved attention and behaviour in children with special needs. Educational & Child Psychology, 22(3), 6777.",
        "Fox, D. J., Tharp, D. F., & Fox, L. C. (2005). Neurofeedback: An alternative and efficacious treatment for attention deficit hyperactivity disorder. Applied Psychophysiology & Biofeedback, 30(4), 365–274.",
        "Fritson, K. K., Wadkins, T. A., Gerdes, P., & Hof, D. (2007). The impact of neurotherapy on college students’ cognitive abilities and emotions. Journal of Neurotherapy, 11(4), 1–9.",
        "Fuchs, T., Birbaumer, N., Lutzenberger, W., Gruzelier, J. H., & Kaiser, J. (2003). Neurofeedback treatment for attention deficit/hyperactivity disorder in children: A comparison with methylphenidate. Applied Psychophysiology and Biofeedback, 28, 112.",
        "Gani C, Birbaumer N & Strehl U.(2008). Long term effects after feedback of slow cortical potentials and of theta-beta amplitudes in children with attention-deficit/hyperactivity disorder(ADHD). International Journal of Bioelectromagnetism, 10(4), 209–232.",
        "Guez, J., Rogel, A., Getter, N., Keha, E., Cohen, T., Amor, T.,…Todder, D. (2015).",
        "Influence of electroencephalography neurofeedback training on episodic memory: a randomized, sham-controlled, double-blind study. Memory, 23(5), 683-694. doi: 10.1080/09658211.2014.921713",
        "Hansen, L. M., Trudeau, D., & Grace, L. (1996). Neurotherapy and drug therapy in combination for adult ADHD, personality disorder, and seizure. Journal of Neurotherapy, 2(1), 6–14.",
        "Hanslmayer, S., Sauseng, P., Doppelmayr, M., Schabus, M., & Klimesch, W. (2005). Increasing individual upper alpha by neurofeedback improves cognitive performance in human subjects. Applied Psychophysiology & Biofeedback, 30(1), 1–10.",
        "Hirshberg, L. M. (2007). Place of electroencephalographic biofeedback for attention-deficit/hyperactivity disorder. Expert Review of Neurotherapeutics, 7(4), 315–319.",
        "Hosseini SMH, Pritchard-Berman M, Sosa N, Ceja A, Kesler SR. Task-based neurofeedback training: A novel approach toward training executive functions. Neuroimage. 2016 Jul 1;134:153-159. doi: 10.1016/j.neuroimage.2016.03.035. Epub 2016 Mar 22.",
        "Hsueh JJ, Chen TS, Chen JJ, Shaw FZ. Neurofeedback training of EEG alpha rhythm enhances episodic and working memory. Hum Brain Mapp. 2016 Jul;37(7):2662-75. doi:10.1002/hbm.23201. Epub 2016 Apr 1. Erratum in: Hum Brain Mapp. 2017 Jun;38(6):3315.",
        "Jackson, G. M., & Eberly, D. A. (1982). Facilitation of performance on an arithmetic task as a result of the application of a biofeedback procedure to suppress alpha wave activity. Biofeedback & Self-Regulation, 7(2), 211–221.",
        "Jacobs, E. H. (2005). Neurofeedback treatment of two children with learning, attention, mood, social, and developmental deficits. Journal of Neurotherapy, 9(4), 55–70.",
        "Kaiser, D. A., & Othmer, S. (2000). Effect of Neurofeedback on variables of attention in a large multi-center trial. Journal of Neurotherapy, 4(1), 5–15.",
        "Kirk, L. (2007). Neurofeedback protocols for subtypes of attention deficit/hyperactivity disorder. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, 267–299.",
        "Koberda, J.L, Moses, A., Koberda, L. and Koberda, P. (2012). Cognitive enhancement using 19-Electrode Z-score neurofeedback. Journal of Neurotherapy, 16(3): 224-230.",
        "Kotwal, D. B., Burns, W. J., & Montgomery, D. D. (1996). Computer-assisted cognitive training for ADHD: A case study. Behavior Modification, 20(1), 85–96.",
        "Kropotov, J. D., Grin-Yatsenko, V. A., Ponomarev, V. A., Chutko, L. S., Yakovenko, E. A., & Nikishena, I. S. (2007). Changes in EEG spectograms, event-related potentials and event-related desynchronization induced by relative beta training in ADHD children. Journal of Neurotherapy, 11(2), 3–11.",
        "Kropotov, J. D., Grin-Yatsenko, V. A., Ponomarev, V. A., Chutko, L. S., Yakovenko, E. A., Nildshena, I. S. (2005). ERPs correlates of EEG relative beta training in ADHD children. International Journal of Psychophysiology, 55(1), 23–34.",
        "Kwon, H., Cho, J., Lee, E. (2009). EEG asymmetry analysis of the left and right brain activities during simple versus complex arithmetic learning. Journal of Neurotherapy, 13(2), 109–116.",
        "Leins, U., Goth, G., Hinterberger, T., Klinger, C., Rumpf, M., & Strehl, U. (2007). Neurofeedback for children with ADHD: A comparison of SCP and theta/beta protocols. Applied Psychophysiology & Biofeedback, 32",
        "Leins, U., Goth, G., Hinterberger, T., Klinger, C., Rumpf, N., & Strehl, U. (2007). Neurofeedback for children with ADHD: A comparison of SCP and theta/beta protocols. Applied Psychophysiology & Biofeedback, 32(2), 73–88.",
        "Levesque, J., Beauregard, M., & Mensour, B. (2006). Effect of neurofeedback training on the neural substrates of selective attention in children with attention–deficit/hyperactivity disorder: a functional magnetic resonance imaging study. Neuroscience Letters, 394(3), 216–221.",
        "Linden, M., Habib, T., & Radojevic, V. (1996). A controlled study of the effects of EEG biofeedback on cognition and behavior of children with attention deficit disorder and learning disabilities. Biofeedback & Self-Regulation, 21(1), 35–49.",
        "Loo, S., & Barkley, R. (2005). Clinical utility of EEG in attention deficit hyperactivity disorder. Applied Neuropsychology, 12(2), 64–76.",
        "Lubar, J. F. (1985). EEG biofeedback and learning disabilities. Theory into Practice, 26, 106–111",
        "Lubar, J. F. (1995). Neurofeedback for the management of attention-deficit/hyperactivity disorders. Chapter in M. S. Schwartz (Ed.), Biofeedback: A Practitioner’s Guide. New York, Guilford, 493–522.",
        "Lubar, J. F. (2003). Neurofeedback for the management of attention-deficit / hyperactivity disorders. Chapter in M. S. Schwartz & F. Andrasik (Eds.), Biofeedback: A Practitioner’s Guide (Third Edition. New York, Guilford, 409–437.",
        "Lubar, J. O., & Lubar, J. F. (1984). Electroencephalographic biofeedback of SMR and beta for treatment of attention deficit disorders in a clinical setting. Biofeedback & Self-Regulation, 9, 1–23.",
        "Lubar, J. F., & Shouse, M. N. (1976). EEG and behavioral changes in a hyperactive child concurrent with training of the sensorimotor rhythm (SMR): A preliminary report. Biofeedback & Self-Regulation, 1(3), 293–306.",
        "Lubar, J. F., & Shouse, M. N. (1977). Use of biofeedback in the treatment of seizure disorders and hyperactivity. Advances in Clinical Child Psychology, 1, 204–251.",
        "Lubar, J. F., Swartwood, M. O., Swartwood, J. N., & O’Donnell, P. H. (1995). Evaluation of the effectiveness of EEG neurofeedback training for ADHD in a clinical setting as measured by changes in T.O.V.A., scores, behavioral ratings, and WISC-R performance. Biofeedback & Self-Regulation, 20(1), 83–99.",
        "Lutzenberger W, Elbert T, Rockstroh B, Birbaumer N. (1982) Biofeedback produced slow brain potentials and task performance. Biological Psychology, 14, 99–111.",
        "McKnight, J. T., & Fehmi, L. G. (2001). Attention and neurofeedback synchrony training: Clinical results and their significance. Journal of Neurotherapy, 5(1–2), 45–62.",
        "Monastra, V. J., (2005). Electroencephalographic biofeedback (neurotherapy) as a treatment for attention deficit hyperactivity disorder: Rationale and empirical foundation. Child & Adolescent Psychiatric Clinics of North America, 14(1), 55–82.",
        "Monastra, V. J., Lynn, S., Linden, M., Lubar, J. F., Gruzelier, J., & LaVaque, T. J. (2005). Electroencephalographic biofeedback in the treatment of attention-deficit/hyperactivity disorder. Applied Psychophysiology & Biofeedback, 30(2), 95–114.",
        "Monastra, V. J., Monastra, D. M., & George, S. (2002). The effects of stimulant therapy, EEG biofeedback, and parenting style on the primary symptoms of attentiondeficit/hyperactivity disorder. Applied Psychophysiology & Biofeedback, 27(4), 231–249.",
        "Mulholland, T. Goodman, D., & Boudrot, R. (1983). Attention and regulation of EEG alpha-attenuation responses. Biofeedback & Self-Regulation, 8(4), 585–600.",
        "Nash, J. K. (2000). Treatment of attention-deficit hyperactivity disorder with neurotherapy. Clinical Electroencephalography, 31(1), 30–37.",
        "Norris, S. L., Lee, C-T., Burshteyn, D., & Cea-Aravena, J. (2001). The effects of performance enhancement training on hypertension, human attention, stress, and brain wave patterns: A case study. Journal of Neurotherapy, 4(3), 29–44.",
        "Norris, S. L., Lee, C., Cea, J., & Burshteyn, D. (1998). Performance enhancement training effects on attention: A case study. Journal of Neurotherapy, 3(1), 19–25.",
        "Orlando, P. C., & Rivera, R. O. (2004). Neurofeedback for elementary students with identified learning problems. Journal of Neurotherapy, 8(2), 5–19.",
        "Othmer, S., Othmer, S. F., & Kaiser, D. A. (1999). EEG biofeedback: Training for AD/HD and related disruptive behavior disorders. Chapter in J. A. Incorvaia & B. F. Mark-Goldstein, & D. Tessmer (Eds.), Understanding, Diagnosing, & Treating AD/HD in Children and Adolescents. New York: Aronson, 235–297",
        "Patrick, G. J. (1996). Improved neuronal regulation in ADHD: An application of 15 sessions of photic-driven EEG neurotherapy. Journal of Neurotherapy, 1(4), 27–36.",
        "Perreau-Linck, E., Lessard, N., Lévesque, J., Beauregard, M. (2010). Effects of neurofeedback training on inhibitory capacities in ADHD children: A single-blind, randomized, placebo-controlled study. Journal of Neurotherapy, 14(3), 229–242.",
        "Pratt, R. R., Abel, H., & Skidmore, J. (1995). The effects of neurofeedback training with background music on EEG patterns of ADD and ADHD children. International Journal of Arts Medicine, 4(1), 24–31.",
        "Pulvermuller, F., Mohr, B., Schleichert, H., & Veit, R. (2000). Operant conditioning of left-hemispheric slow cortical potentials and its effect on word processing. Biological Psychology, 53, 177–215.",
        "Putman, J. A., Othmer, S. F., Othmer, S., & Pollock, V. E. (2005). TOVA results following interhemispheric bipolar EEG training. Journal of Neurotherapy, 9(1), 37–52.",
        "Rasey, H. W., Lubar, J. E., McIntyre, A., Zoffuto, A. C., & Abbott, P. L. (1996). EEG biofeedback for the enhancement of attentional processing in normal college students. Journal of Neurotherapy, 1(3), 15–21.",
        "Rockstroh, B., Elbert, T., Lutzenberger, W., & Birbaumer, N. (1990). Biofeedback: Evaluation and therapy in children with attentional dysfunction. Chapter in A. Rothenberger (Ed.), Brain and Behaviour in Child Psychiatry. Berlin: Springer Verlag, 345–357.",
        "Rossiter, T. R. (2004). The effectiveness of neurofeedback and stimulant drugs in treating AD/HD: Part I. Review of methodological issues. Applied Psychophysiology & Biofeedback, 29(2), 135–140.",
        "Rossiter, T. R. (2005). The effectiveness of neurofeedback and stimulant drugs in treating AD/HD: Part II. Replication. Applied Psychophysiology & Biofeedback, 29(4), 233–243.",
        "Rossiter, T. (2002). Neurofeedback for AD/HD: A ratio feedback case study. Journal of Neurotherapy, 6(3), 9–35.",
        "Rossiter, T. R. (1998). Patient directed neurofeedback for ADHD. Journal of Neurotherapy, 2(4), 54–63.",
        "Rossiter, T. R., & La Vaque, T. J. (1995). A comparison of EEG biofeedback and psychostimulants in treating attention deficit/hyperactivity disorders. Journal of Neurotherapy, 1(1), 48–59.",
        "Russell, H. L., & Carter, J. L. (1997). EEG driven audio-visual stimulation unit for enhancing cognitive abilities of learning disordered boys: Final report. Washington, D.C.: U.S. Department of Education (SBIR), Contract number RA94130002.",
        "Scheinbaum, S., Zecker, S., Newton, C. J., & Rosenfeld, P. (1995 ). A controlled study of EEG biofeedback as a treatment for attention-deficit disorders. In “Proceedings of the 26th Annual Meeting of the Association for Applied Psychophysiology and Biofeedback” 131–134.",
        "Sheer, D. E. (1975). Biofeedback training of 40-Hz EEG and behavior. Chapter in N. Burch & H. I. Altshuler (Eds.), Behavior and Brain Electrical Activity. New York: Plenum.",
        "Sheer, D. E. (1977). Biofeedback training of 40-Hz EEG and behavior. Chapter in J. Kamiya et al., Biofeedback and Self-Control 1976/1977. An Annual Review. Chicago: Aldine.",
        "Shin, D. I., Lee, J. H., Lee, S. M., Kim, I. Y., & Kim, S. I. (2004). Neurofeedback training with virtual reality for inattention and impulsiveness. Cyberpsychology & Behavior, 7(5), 519–526.",
        "Shouse, M. N., & Lubar, J. F. (1979). Operant conditioning of EEG rhythms and Ritalin in the treatment of hyperkinesis. Biofeedback & Self-Regulation, 4(4), 299–311.",
        "Stankus, T. (2008). Can the brain be trained? Comparing the literature on the use of EEG biofeedback/neurofeedback as an alternative or complementary therapy for attention deficit disorder (ADHD). Behavioral & Social Sciences Librarian, 26(4), 20–56.",
        "Strehl, U., Leins, U., Goth, G., Klinger, C., Hinterberger, T., and Birbaumer, N. (2006). Self-regulation of slow cortical potentials: A new treatment for children with attentiondeficit/hyperactivity disorder. Pediatrics, 118, 1530–1540.",
        "Surmeli, T., & Ertem, A. (2007). EEG neurofeedback treatment of patients with Down Syndrome. Journal of Neurotherapy, 11(1), 63–68.",
        "Surmeli, T., & Ertem, A. (2010). Post WISC-R and TOVA improvement with QEEG guided neurofeedback training in mentally retarded: A clinical case series of behavioral problems. Clinical EEG & Neuroscience Journal, 41(1), 32–41.",
        "Swingle, P. G. (2002). Parameters associated with rapid neurotherapeutic treatment of common ADD (CADD). Journal of Neurotherapy, 5(4), 73–84.",
        "Swingle, P. G. (1996). Sub threshold 10-Hz sound suppresses EEG theta: Clinical application for the potentiation of neurotherapeutic treatment of ADD/ADHD. Journal of Neurotherapy, 2(1), ",
        "15–22.",
        "Tansey, M. A. (1984). EEG sensorimotor rhythm biofeedback training: Some effects on the neurological precursors of learning disabilities. International Journal of Psychophysiology, 3, 85–99.",
        "Tansey, M. A. (1985). Brainwave signatures—An index reflective of the brain=s functional neuroanatomy: Further findings on the effect of EEG sensorimotor rhythm biofeedback training on the neurologic precursors of learning disabilities. International Journal of Psychophysiology, 3, 85–89.",
        "Tansey, M. A. (1990). Righting the rhythms of reason: EEG biofeedback training as a therapeutic modality in a clinical office setting. Medical Psychotherapy, 3, 57–68.",
        "Tansey, M. A. (1991). Wechsler (WISC-R) changes following treatment of learning disabilities via EEG biofeedback in a private practice setting. Australian Journal of Psychology, 43, 147–153.",
        "Tansey, M. A. (1993). Ten-year stability of EEG biofeedback results for a hyperactive boy who failed fourth grade perceptually impaired class. Biofeedback & Self-Regulation, 18, 33–44.",
        "Tansey, M. A., & Bruner, R. L. (1983). EMG and EEG biofeedback training in the treatment of 10-year old hyperactive boy with a developmental reading disorder. Biofeedback & Self-Regulation, 8(1), 25–37.",
        "Thompson, L., & Thompson, M. (1998). Neurofeedback combined with training in metacognitive strategies: Effectiveness in students with ADD. Applied Psychophysiology & Biofeedback, 23(4), 243–263.",
        "Thornton, K. E., & Carmody, D. P. (2005). Electroencephalogram biofeedback for reading disability and traumatic brain injury. Child & Adolescent Psychiatric Clinics of North America, 14(1), 137–162.",
        "Thornton, K. E., & Carmody, D. P. (2013). The relation between memory improvement and QEEG changes in three clinical groups as a result of EEG biofeedback treatment. Journal of Neurofeedback",
        "Thornton, K. E., & Carmody, D. P. (2014). The Coordinated Allocation of Resource (CAR) Electrophysiological Patterns of Recalling Names of Faces in Children, Adolescents and Adults and the Central Processing Unit (CPU) of the Brain. Journal of NeuroRegulation, 1(1), 87-104.",
        "Tinius, T. P., & Tinius, K. A. (2001). Changes after EEG biofeedback and cognitive retraining in adults with mild traumatic brain injury and attention deficit disorder. Journal of Neurotherapy, 4(2), 27–44.",
        "Williams, J. (2010). Does neurofeedback help reduce attention-deficit hyperactivity disorder? Journal of Neurotherapy, 14(4), 261–279.",
        "Vachon-Presseau, E., Achim, A., Benoit-Lajoie, A. (2009). Direction of SMR and beta change with attention in adults. Journal of Neurotherapy, 13(1), 22–29.",
        "Valdez, M. (1985). Effects of biofeedback-assisted attention training in a college population. Biofeedback & Self-Regulation, 10(4), 315–324.",
        "Vernon, D., Egner, T., Cooper, N., Compton, T., Neilands, C., Sheri, A., & Gruzelier, J. (2003). The effect of training distinct neurofeedback protocols on aspects of cognitive performance. International Journal of Psychophysiology, 47, 75–85.",
        "Wadhwani, S., Radvanski, D. C., & Carmody, D. P. (1998). Neurofeedback training in a case of attention deficit hyperactivity disorder. Journal of Neurotherapy, 3(1), 42–49.",
        "Walker, J. E., & Norman, C. A. (2006). The neurophysiology of dyslexia: A selective review with implications for neurofeedback remediation and results of treatment in twelve consecutive patients. Journal of Neurotherapy, 10(1), 45–55.",
        "Warner, D.A., Barabasz, A., & Barabasz, M. (2000). The efficacy of Barabasz’s alert hypnosis and neurotherapy on attentiveness, impulsivity and hyperactivity in children with ADHD. Child Study Journal, 30(1), 43–49.",
        "Xiong, Z., Shi, S., & Xu, H. (2005). A controlled study of the effectiveness of EEG biofeedback training on children with attention deficit hyperactivity disorder. Journal of Huazhong University of Science & Technology, 25(3), 368–370.",
      ],
    },
    {
      title: "CONCUSSION/MILD TRAUMATIC BRAIN INJURY",
      articles: [
        "Ayers, M. E. (1981). A report on a study of the utilization of electroencephalography for the treatment of cerebral vascular lesion syndromes. Chapter in L. Taylor, M. E. Ayers, & C. Tom (Eds.), Electromyometric Biofeedback Therapy. Los Angeles: Biofeedback and Advanced Therapy Institute, 244–257.",
        "Ayers, M. E. (1987). Electroencephalic neurofeedback and closed head injury of 250 individuals. Head Injury Frontiers. National Head Injury Foundation, 380–392.",
        "Ayers, M. E. (1991). A controlled study of EEG neurofeedback training and clinical psychotherapy for right hemispheric closed head injury. Paper presented at the National Head Injury Foundation, Los Angeles, 1991.",
        "Ayers, M. E. (1995a). A controlled study of EEG neurofeedback and physical therapy with pediatric stroke, age seven months to age fifteen, occurring prior to birth. Biofeedback & Self-Regulation, 20(3), 318.",
        "Ayers, M. E. (1995b). EEG neurofeedback to bring individuals out of level 2 coma. Biofeedback & Self-Regulation, 20(3), 304–305.",
        "Ayers, M. E. (1999). Assessing and treating open head trauma, coma, and stroke using real-time digital EEG neurofeedback. Chapter in J. R. Evans & A. Abarbanel (Eds.), Introduction to Quantitative EEG and Neurofeedback. New York: Academic Press, 203–222.",
        "Ayers, M. E. (2004). Neurofeedback for cerebral palsy. Journal of Neurotherapy, 8(2), 9394.",
        "Bachers, A. (2004). Neurofeedback with cerebral palsy and mental retardation. Journal of Neurotherapy, 8(2), 95–96.",
        "Bearden, T. S., Cassisi, J. E., & Pineda, M. (2003). Neurofeedback training for a patient with thalamic and cortical infarctions. Applied Psychophysiology & Biofeedback, 28(3), 241–253.",
        "Bounias, M., Laibow, R. E., Bonaly, A., & Stubblebine, A. N. (2002). EEGneurobiofeedback treatment of patients with brain injury: Part 1: Typological classification of clinical syndromes. Journal of Neurotherapy, 5(4), 23–44.",
        "Bounias, M., Laibow, R. E., Stubbelbine, A. N., Sandground, H., & Bonaly, A. (2002). EEGneurobiofeedback treatment of patients with brain injury Part 4: Duration of treatments as a function of both the initial load of clinical symptoms and the rate of rehabilitation. Journal of Neurotherapy, 6(1), 23–38.",
        "Byers, A. P. (1995). Neurofeedback therapy for a mild head injury. Journal of Neurotherapy, 1(1), 22–37.",
        "Cannon, K. B., Sherlin, L., & Lyle, R. R. (2010). Neurofeedback efficacy in the treatment of a 43-year-old female stroke victim: a case study. Journal of Neurotherapy, 14(2), 107–121.",
        "Doppelmayr, M., Nosko, H., Pecherstorfer, T., & Fink, A. (2007). An attempt to increase cognitive performance after stroke with neurofeedback. Biofeedback, 35(4), 126–130.",
        "Duff, J. (2004). The usefulness of quantitative EEG (QEEG) and neurotherapy in the assessment and treatment of post-concussion syndrome. Clinical EEG & Neuroscience, 35(4), 198–209.",
        "Hammond, D. C. (2005). Neurofeedback to improve physical balance, incontinence, and swallowing. Journal of Neurotherapy, 9(1), 27–48.",
        "Hammond, D. C. (2007). Can LENS neurofeedback treat anosmia resulting from a head injury? Journal of Neurotherapy, 11(1), 57–62.",
        "Hoffman, D. A., Stockdale, S., & Van Egren, L. (1996a). Symptom changes in the treatment of mild traumatic brain injury using EEG neurofeedback [Abstract]. Clinical Electroencephalography, 27(3), 164.",
        "Hoffman, D. A., Stockdale, S., & Van Egren, L. (1996b). EEG neurofeedback in the treatment of mild traumatic brain injury [Abstract]. Clinical Electroencephalography, 27(2), 6.",
        "Keller, I. (2001). Neurofeedback therapy of attention deficits in patients with traumatic brain injury. Journal of Neurotherapy, 5(1-2), 19–32.",
        "Keller, I., Garbacenkaite, R., (2015). Neurofeedback in three patients in the state of unresponsive wakefulness. Applied Psychophysiology & Biofeedback, 40(4), 349-356. doi: 10.1007/s10484-015-9296-7",
        "Koberda, J. L. (2015). LORETA z-score neurofeedback-effectiveness in rehabilitation of patients suffering from traumatic brain injury. Journal of Neurology and Neurobiology, 1 (4).",
        "Koberda J, L. and Stodolska-Koberda U (2014). Z-score LORETA Neurofeedback as a Potential Rehabilitation Modality in Patients with CVA. Journal of Neurology and Stroke 1(5): 00029.",
        "Laibow, R E., Stubblebine, A. N., Sandground, H.,& Bounias, M. (2002). EEG neurobiofeedback treatment of patients with brain injury: Part 2: Changes in EEG parameters versus rehabilitation. Journal of Neurotherapy, 5(4), 45–71",
        "Nelson, D., & Esty, M. (2012). Neurotherapy of traumatic brain injury/posttrumatic stress symptoms in oef/oif veterans. The Journal of Neuropsychiatry and Clinical Neurosciences, 24(2), 237-240.",
        "Putman, J. A., (2002). EEG biofeedback on a female stroke patient with depression: A case study. Journal of Neurotherapy, 5(3), 27–38.",
        "Rozelle, G. R., & Budzynski, T. H. (1995). Neurotherapy for stroke rehabilitation: A single case study. Biofeedback & Self-Regulation, 20(3), 211–228.",
        "Schoenberger, N. E., Shiflett, S. C., Esty, M. L., Ochs, L., & Matheis, R. J. (2001). Flexyx neurotherapy system in the treatment of traumatic brain injury: An initial evaluation. Journal of Head Trauma Rehabilitation, 16(3), 260–274.",
        "Swatzyna, R.J. (2009). The Elusive Nature of Mild Traumatic Brain Injury. Biofeedback, 37(3): 92-95.",
        "Thatcher, R. W. (2000). EEG operant conditioning (biofeedback) and traumatic brain injury. Clinical Electroencephalography, 31(1), 38–44.",
        "Thornton, K. (2000). Improvement/rehabilitation of memory functioning with neurotherapy/QEEG biofeedback. Journal of Head Trauma Rehabilitation, 15(6), 12851296.",
        "Thornton, K. (2002) The improvement/rehabilitation of auditory memory functioning with EEG biofeedback. Neurorehabilitation, 17(1), 69–81.",
        "Thornton, K. E., & Carmody, D. P. (2005). Electroencephalogram biofeedback for reading disability and traumatic brain injury. Child & Adolescent Psychiatric Clinics of North America, 14(1), 137–162.",
        "Thornton, K. E., & Carmody, D. P. (2008). Efficacy of traumatic brain injury rehabilitation: Interventions of QEEG-guided biofeedback, computers, strategies, and medications. Applied Psychophysiology & Biofeedback, 33(2), 101–124.",
        "Tinius, T. P., & Tinius, K. A. (2001). Changes after EEG biofeedback and cognitive retraining in adults with mild traumatic brain injury and attention deficit disorder. Journal of Neurotherapy, 4(2), 27–44.",
        "Walker, J. E. (2007). A neurologist’s experience with QEEG-guided neurofeedback following brain injury. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, 353–361.",
        "Wing, K. (2001). Effect of neurofeedback on motor recovery of a patient with brain injury: A case study and its implications for stroke rehabilitation. Topics in Stroke Rehabilitation, 8(3), 45–53.",
        "Yoo, S. S., & Jolesz, F. A. (2002). Functional MRI for neurofeedback: feasibility study on a hand motor task. Neuroreport, 13, 1377–1381.",
      ],
    },
    {
      title: "CRIMINAL/JUVENILE OFFENDERS",
      articles: [
        "Martin, G., & Johnson, C. L. (2005). The Boys Totem Town Neurofeedback Project: A pilot study of EEG biofeedback with incarcerated juvenile felons. Journal of Neurotherapy, 9(3), 71–86.",
        "Quirk, D. A. (1995). Composite biofeedback conditioning and dangerous offenders: III. Journal of Neurotherapy, 1(2), 44–54.",
        "Smith, P. N., & Sams, M. W. (2005). Neurofeedback with juvenile offenders: A pilot study in the use of QEEG-based and analog-based remedial neurofeedback training. Journal of Neurotherapy, 9(3), 87–99.",
      ],
    },
    {
      title: "DEPRESSION/MOOD",
      articles: [
        "Allen, J. B., & Cavendar, J. H. (1996). Biofeedback alters EEG asymmetry. Psychophysiology, 33(suppl), S17, (Abstract).",
        "Baehr, E., & Baehr, R. (1997). The use of brainwave biofeedback as an adjunctive therapeutic treatment for depression: Three case studies. Biofeedback, 25(1), 10–11.",
        "Baehr, E., Miller, E., Rosenfeld, J. P., & Baehr, R. (2004). Changes in frontal brain asymmetry associated with premenstrual dysphoric disorder: A single case study. Journal of Neurotherapy, 8(1), 29–42.",
        "Baehr, E., Rosenfeld, J. P., & Baehr, R. (1997). The clinical use of an alpha asymmetry protocol in the neurofeedback treatment of depression: Two case studies. Journal of Neurotherapy, 2(3), 10–23.",
        "Baehr, E., Rosenfeld, J. P., & Baehr, R. (2001). Clinical use of an alpha asymmetry neurofeedback protocol in the treatment of mood disorders: Follow-up study one to five years post therapy. Journal of Neurotherapy, 4(4), 11–18.",
        "Berg, K., Siever, D. (2009). A controlled comparison of audio-visual entrainment for treating Seasonal Affective Disorder. Journal of Neurotherapy, 13(3), 166–175.",
        "Bodurka J. Randomized Clinical Trial of Real-Time fMRI Amygdala Neurofeedback for Major Depressive Disorder: Effects on Symptoms and Autobiographical Memory Recall. Am J Psychiatry. 2017 Aug 1;174(8):748-755. doi: 10.1176/appi.ajp.2017.16060637. Epub 2017 Apr 14.",
        "Cantor, D.S., Stevens, E. (2009). QEEG correlates of auditory-visual entrainment treatment efficacy of refractory depression. Journal of Neurotherapy, 13(2), 100–108.",
        "Cheon, E.J., Koo, B.H., Choi, J.H. (2016). The efficacy of neurofeedback in patients with major depressive disorder: an open labeled prospective study. Applied Psychophysiology & Biofeedback, 41(1), 103-110. doi: 10.1007/s10484-015-9315-8",
        "Grin-Yatsenko, V. A., Othmer, S, Ponomarev, V. A., Evdokimov, S. A., Konoplev, Y. Y., Kroptov, J. D. (2018) Infra-Low Frequency Neurofeedback in Depression: Three case studies. NeuroRegulation 5(1), 30-42.",
        "Hammond, D. C. (2005). Neurofeedback with anxiety and affective disorders. Child & Adolescent Psychiatric Clinics of North America, 14(1), 105–123",
        "Hammond, D. C. (2001). Neurofeedback treatment of depression with the Roshi. Journal of Neurotherapy, 4(2), 45–56.",
        "Hammond, D. C. (2002). Neurofeedback training for anger control. Journal of Neurotherapy, 5(4), 98–103.",
        "Hardman, E., Gruzelier, J., Chessman, K., Jones, C., Liddiard, D., Schleichert, H., & Birbaumer, N. (1997). Frontal interhemispheric asymmetry: Self-regulation and individual differences in humans. Neuroscience Letters, 221, 117–120.",
        "Jenkins, P., & Moore, W. H. (1985).The effects of visual feedback on hemispheric alpha asymmetries and reported processing strategies: A single-subject experimental design. Brain & Cognition, 4(1), 47–58.",
        "Kotchoubey, B., Schleichert, H., Lutzenberger, W., Anokhin, A. P., & Birbaumer, N. (1996). Self-regulation of interhemispheric asymmetry in humans. Neuroscience Letters, 215, 91–94.",
        "Kumano, H., Horie, H., Shidara, T., Kuboki, T. et al. (1996). Treatment of a depressive disorder patient with EEG-driven photic stimulation. Biofeedback & Self-Regulation, 21(4), 323–334.",
        "Putman, J. A., (2002). EEG biofeedback on a female stroke patient with depression: A case study. Journal of Neurotherapy, 5(3), 27–38.",
        "Raymond, J., Varney, C., Parkinson, L. A., & Gruzelier, J. H. (2005). The effects of alpha/theta neurofeedback on personality and mood. Cognitive Brain Research, 23, 287–292.",
        "Rosenfeld, J. P. (2000). An EEG biofeedback protocol for affective disorders. Clinical Electroencephalography, 31(1), 7–12.",
        "Rosenfeld, J. P. (1997). EEG biofeedback of frontal alpha asymmetry in affective disorders. Biofeedback, 25(1), 8–25.",
        "Rosenfeld, J. P., Baehr, E., Baehr, R., Gotlib, I. H., & Ranganath, C. (1996). Preliminary evidence that daily changes in frontal alpha asymmetry correlate with changes in affect in therapy sessions. International Journal of Psychophysiology, 23, 137–141.",
        "Rosenfeld, J. P., Cha, G., Blair, T., & Gotlib, I. (1995). Operant biofeedback control of left-right frontal alpha power differences. Biofeedback & Self-Regulation, 20, 241–258.",
        "Saxby, E., & Peniston, E. G. (1995). Alpha-theta brainwave neurofeedback training: an effective treatment for male and female alcoholics with depressive symptoms. Journal of Clinical Psychology, 51, 685–693.",
        "Schneider, F., Heimann, H., Mattes, R., Lutzenberger, W., & Birbaumer, N. (1992). Self-regulation of slow cortical potentials in psychiatric patients: Depression. Biofeedback & Self-Regulation, 17, 203–214.",
        "Tsai, H., Peper, E. Lin, I. (2016). EEG patterns under positive/negative body postures and emotion recall tasks. NeuroRegulation 3(1), 23-27.",
        "Uhlmann, C., & Froscher, W. (2001). Biofeedback treatment in patients with refractory epilepsy: Changes in depression and control orientation. Seizure, 10, 34–38.",
        "Walker, J. E., Lawson, R., & Kozlowski, G. (2007). Current status of QEEG and neurofeedback in the treatment of depression. Chapter in J. R. Evans (Ed.), Handbook of Neurofeedback. Binghampton, NY: Haworth Medical Press, 341–351.",
        "Walker, J., (2013). QEEG-guided neurofeedback for anger/anger control disorder.  Journal of Neurotherapy",
        "Wang, S.-Y., Lin, I.-M., Peper, E., Chen, Y.-T., Yeh, Y.-C., …. Chu, C.-C. (2016). The efficacy of neurofeedback among patients with major depressive disorder. Preliminary study. NeuroRegulation, 3(3), 127-134.",
      ],
    },
    {
      title: "DISSOCIATION",
      articles: [
        "Jacobs, R.L. (2009). Rhythms of healing: A case study. Journal of Neurotherapy, 13(4), 228–238.",
        "Manchester, C., Allen, T., & Tachiki, K. H. (1998). Treatment of dissociative identity disorder with neurotherapy and group self-exploration. Journal of Neurotherapy, 2(4), 40–53.",
        "Mason, L. A., & Brownback, T. S. (2001). Optimal functioning training with EEG biofeedback for clinical populations: A case study. Journal of Neurotherapy, 5(1–2), 3344.",
      ],
    },
    {
      title: "OBSESSIVE COMPULSIVE DISORDER",
      articles: [
        "Hammond, D. C. (2003). QEEG-guided neurofeedback in the treatment of obsessive compulsive disorder. Journal of Neurotherapy, 7(2), 25–52.",
        "Hammond, D. C. (2004). Treatment of the obsessional subtype of obsessive compulsive disorder with neurofeedback. Biofeedback, 32, 9–12.",
        "Mills, G. K., & Solyom, L. (1974). Biofeedback of EEG alpha in the treatment of obsessive ruminations: An exploration. Journal of Behaviour Therapy & Experimental Psychiatry, 5, 37–41.",
        "Sürmeli T, Ertem A. (2011) Obsessive compulsive disorder and the efficacy of qEEG-guided neurofeedback treatment: a case series. Clinical EEG Neuroscience Journal. 42(3), 195-201.",
      ],
    },
    {
      title: "PERFORMANCE OPTIMIZATION",
      articles: [
        "Arns, M., Kleinnijenhuis, M., Fallahpour, K., & Bretler, R. (2007). Golf performance enhancement and real-life neurofeedback training using personalized event-locked EEG profiles. Journal of Neurotherapy, 11(4), 11–18.",
        "Boynton, T. (2001). Applied research using alpha/theta training for enhancing creativity and well-being. Journal of Neurotherapy, 5(1–2), 5–18.",
        "Egner, T., & Gruzelier, J. H. (2003). Ecological validity of neurofeedback: Modulation of slow wave EEG enhances musical performance. NeuroReport, 14(9), 1221–1224.",
        "Gruzelier, J., Egner, T., & Vernon, D. (2006). Validating the efficacy of neurofeedback for optimising performance. Progress in Brain Research, 159, 421–431.",
        "Gruzelier, J. (2009). A theory of alpha/theta neurofeedback, creative performance enhancement, long distance functional connectivity and psychological integration. Cognitive Processing, 10 (Suppl 1), S101–109.",
        "Raymond, J., Sajid, I., Parkinson, L. A., & Gruzelier, J. H. (2005). Biofeedback and dance performance: A preliminary investigation. Applied Psychophysiology & Biofeedback, 30(1), 65–74.",
        "Thompson, M. & Thompson, L. (2003) The neurofeedback book: An Introduction to basic Concepts in Applied Psychophysiology, Wheat Ridge, CO: Association for Applied Psychophysiology",
        "Thompson, T., Steffert, T., Ros, T., Leach, J., & Gruzelier, J. (2008). EEG applications for sport and performance. Methods, 45, 279–288.",
        "Vernon, D., Dempster, T., Bazanova, O., Rutterford, N., Pasqualini, M., Andersen, S. (2009). Alpha neurofeedback training for performance enhancement: Reviewing the methodology. Journal of Neurotherapy, 13(4), Pages 214–227.",
        "Vernon, D. J. (2005). Can neurofeedback training enhance performance? An evaluation of the evidence with implications for future research. Applied Psychophysiology & Biofeedback, 30(4), 347–364.",
      ],
    },
    {
      title: "PTSD",
      articles: [
        "Bell, A. N. (2018). Tuning the traumatized brain, mind, and heart: Loreta z-score neurofeedback and HRV biofeedback for chronic PTSD [Dissertation Research]. Available from ProQuest Dissertations & Theses Global. (2190681731). Retrieved from https://tcsedsystem.idm.oclc.org/login?url=https://search-proquest-com.tcsedsystem.idm.oclc.org/docview/2190681731?accountid=34120",
        "Bluhm, R. L., Williamson, P. C., Osuch, E. A., Frewen, P. A., Stevens, T. K., Boksman, K., … Lanius, R. A. (2009). Alterations in default network connectivity in posttraumatic stress disorder related to early-life trauma. Journal of Psychiatry & Neuroscience: JPN, 34(3), 187–194.",
        "Bracciano, A,G., Chang, W-P., Kokesh, S. (2012). Cranial electrotherapy stimulation in the treatment of posttraumatic stress disorder: A pilot study of two military veterans. Journal of Neurotherapy.",
        "Currie, C. L., Remley, T. P., & Craigen, L. (2014). Treating trauma survivors with neurofeedback: A grounded theory study. NeuroRegulation, 1(3–4), 219.",
        "Foster, D. S., & Thatcher, R. W. (2015). Surface and LORETA neurofeedback in the treatment of post-traumatic stress disorder and mild traumatic brain injury. In R. W. Thatcher & D. S. Foster (Eds.), Z score neurofeedback: Clinical applications (pp. 59–92). San Diego, CA: Academic Press.",
        "Fragedakis, T.M., Toriello, P. (2014). The Development and experience of combat-related PTSD: a demand for neurofeedback as an effective form of treatment. Journal of Counseling & Development, 92(4), 481-488. doi: 10.1002/j.1556-6676.2014.00174.x",
        "Gapen, M., van der Kolk, B. A., Hamlin, E., Hirshberg, L., Suvak, M., Spinazzola J.(2016). A pilot study of neurofeedback for chronic PTSD. Applied Psychophysiology and Biofeedback. doi:",
        "Graap, K., Ready, D. J., Freides, D., Daniels, B., & Baltzell, D. (1997). EEG biofeedback treatment for Vietnam veterans suffering from posttraumatic stress disorder. Journal of Neurotherapy, 2(3), 65–66. [Conference Paper]",
        "Huang-Storms, L., Bodenhamer-Davis, E., Davis, R., & Dunn, J. (2007). QEEG-guided neurofeedback for children with histories of abuse and neglect: Neurodevelopmental rationale and pilot study. Journal of Neurotherapy, 10(4), 3–16. Retrieved from https://doi.org/10.1300/J184v10n04_02",
        "Imperatori, C., Farina, B., Quintiliani, M. I., Onofri, A., Castelli Gattinara, P., Lepore, M., … Della Marca, G. (2014). Aberrant EEG functional connectivity and EEG power spectra in resting state post-traumatic stress disorder: A sLORETA study. Biological Psychology, 102, 10–17. Retrieved from https://doi.org/10.1016/j.biopsycho.2014.07.011",
        "Jokić-Begić, N., & Begić, D. (2003). Quantitative electroencephalogram (qEEG) in combat veterans with post-traumatic stress disorder (PTSD). Nordic Journal of Psychiatry, 57(5), 351–355. Retrieved from https://doi.org/10.1080/08039480310002688",
        "Kluetsch, R. C., Ros, T., Théberge, J., Frewen, P. A., Calhoun, V. D., Schmahl, C., …Lanius, R. A. (2014). Plastic modulation of PTSD resting-state networks and subjective wellbeing by EEG neurofeedback. Acta Psychiatrica Scandinavica, 130(2),123-136. doi: 10.1111/acps.12229",
        "Kluetsch, R., Ros, T., Theberge, J., Frewen, P., Schmahl, C., & Lanius, R. (2012). Increased default mode network connectivity following EEG neurofeedback in PTSD. In International Society for Traumatic Stress Studies (ISTSS) ",
        "28th Annual Meeting: Innovations to Expand Services and Tailor Traumatic Stress Treatments, November 1-3, 2012, Los Angeles, CA [Abstracts]. International Society for Traumatic Stress Studies (ISTSS). Retrieved from https://doi.org/10.1037/e533652013-382",
        "Lanius, R. A., Frewen, P. A., Tursich, M., Jetly, R., & McKinnon, M. C. (2015). Restoring large-scale brain networks in PTSD and related disorders: A proposal for neuroscientifically-informed treatment interventions. European Journal of Psychotraumatology, 6. Retrieved from https://doi.org/10.3402/ejpt.v6.27313",
        "Lanius, R. A., Vermetten, E., Loewenstein, R. J., Brand, B., Schmahl, C., Bremner, J. D., & Spiegel, D. (2010). Emotion modulation in PTSD: Clinical and neurobiological evidence for a dissociative subtype. American Journal of Psychiatry, 167(6), 640–647. Retrieved from https://doi.org/10.1176/appi.ajp.2009.09081168",
        "Mills, Z. L. (2012). Neurofeedback experiences of clients with hyperarousal symptoms associated with PTSD. The University of the Rockies. Retrieved from http://gradworks.umi.com/35/44/3544522.html",
        "Othmer, S. (2012). Psychological health and neurofeedback: Remediating PTSD and TBI. Retrieved from http://www.eeginfo-europe.com/fileadmin/images/research/anxiety/RemediatingPTSD_TBI.pdf",
        "Othmer, S., & Othmer, S. F. (2009). Post traumatic stress disorder: The neurofeedback remedy. Biofeedback, 37(1), 24–31.",
        "Peniston, E. G., & Kulkosky, P. J. (1991). Alpha-theta brainwave neuro-feedback therapy for Vietnam veterans with combat-related post-traumatic stress disorder. Medical Psychotherapy, 4, 47–60.",
        "Peniston, E. G., Marrinan, D. A., Deming, W. A., & Kulkosky, P. J. (1993). EEG alpha-theta brainwave synchronization in Vietnam theater veterans with combat-related post-traumatic stress disorder and alcohol abuse. Advances in Medical Psychotherapy, 6, 37–50.",
        "Pop-Jordanova, N., & Zorcec, T. (2004). Child trauma, attachment and biofeedback mitigation. Prilozi / Makedonska Akademija Na Naukite I Umetnostite, Oddelenie Za Biološki I Medicinski Nauki = Contributions / Macedonian Academy of Sciences and Arts, Section of Biological and Medical Sciences, 25(1–2), 103–114.",
        "Putman, J. (2000). The effects of brief, eyes-open alpha brain wave training with audio and video relaxation induction on the EEG of 77 Army reservists. Journal of Neurotherapy, 4(1), 17–28.",
        "Nelson, D., & Esty, M. (2012). Neurotherapy of traumatic brain injury/posttrumatic stress symptoms in oef/oif veterans. The Journal of Neuropsychiatry and Clinical Neurosciences, 24(2), 237-240.",
        "Reiter, K., Andersen, S. B., Carlsson, J. (2016). Neurofeedback treatment and posttraumatic stress disorder: effectiveness of neurofeedback on posttraumatic stress disorder and the optimal choice of protocol. The Journal of Nervous and Mental Disease, 204(2), 69-77.",
        "Ros, T., J. Baars, B., Lanius, R. A., & Vuilleumier, P. (2014). Tuning pathological brain oscillations with neurofeedback: A systems neuroscience framework. Frontiers in Human Neuroscience, 8. Retrieved from https://doi.org/10.3389/fnhum.2014.01008",
        "Ros, T., Théberge, J., Frewen, P. A., Kluetsch, R., Densmore, M., Calhoun, V. D., & Lanius, R. A. (2013). Mind over chatter: Plastic up-regulation of the fMRI salience network directly after EEG neurofeedback. NeuroImage, 65, 324–335. https://doi.org/10.1016/j.neuroimage.2012.09.046",
        "Russo, G. M., Novian, D. A. (2014). A Research Analysis of Neurofeedback Protocols for PTSD and Alcoholism. Journal of NeuroRegulation, 1(2), 183-186.",
        "Tan, G., Dao, T. K., Farmer, L., Sutherland, R. J., & Gevirtz, R. (2011). Heart rate variability (HRV) and posttraumatic stress disorder (PTSD): A pilot study. Applied Psychophysiology and Biofeedback, 36(1), 27–35. Retrieved from https://doi.org/10.1007/s10484-010-9141-y",
        "van der Kolk, B. A., Hodgdon, H., Gapen, M., Musicaro, R., Suvak, M. K., Hamlin, E., & Spinazzola, J. (2016). A Randomized Controlled Study of Neurofeedback for Chronic PTSD. PLOS ONE, 11(12), e0166752. Retrieved from https://doi.org/10.1371/journal.pone.0166752",
      ],
    },
    {
      title: "HEADACHE/MIGRAINE",
      articles: [
        "Bazanova, O.M., Aftanas, L.I. (2010). Individual EEG alpha activity analysis for enhancement neurofeedback efficiency: Two case studies. Journal of Neurotherapy, 14(3), 244–253.",
        "Coger, R., & Werbach, M. (1975). Attention, anxiety, and the effects of learned enhancement of EEG alpha in chronic pain: A pilot study in biofeedback. Chapter in B. L. Drue, Jr. (Ed.), Pain Research and Treatment. New York: Academic Press.",
        "Gannon, L., & Sternbach, R. A. (1971). Alpha enhancement as a treatment for pain: A case study. Behavior Therapy & Experimental Psychiatry, 2, 209–213.",
        "Ham, L. P., & Packard, R. C. (1996). A retrospective, follow-up study of biofeedback-assisted relaxation therapy in patients with posttraumatic headache. Biofeedback & Self-Regulation, 21(2), 93–104.",
        "Jensen, M.P., Sherlin, L.H., Hakimian, S., Fregni, F. (2009). Neuromodulatory approaches for chronic pain management: Research findings and clinical implications. Journal of Neurotherapy, 13(4), 196–213.",
        "Koberda JL, Koberda P, Bienkiewicz A, Moses A, Koberda L. Pain Management Using 19-Electrode Z-Score LORETA Neurofeedback. Journal of Neurotherapy, 2013, 17:3, 179-190.",
        "Lehmann, D., Lang, W., & Debruyne, P. (1976). Controlled EEG alpha feedback training in normals and headache patients. Archives of Psychiatry, 221, 331–343.",
        "Matthew, A., Mishm, H., & Kumamiah, V. (1987). Alpha feedback in the treatment of tension headache. Journal of Personality & Clinical Studies, 3(1), 17–22.",
        "McKenzie, R., Ehrisman, W., Montgomery, P. S., & Barnes, R. H. (1974). The treatment of headache by means of electroencephalographic biofeedback. Headache, 13, 164–172.",
        "Jensen, M. P., Grierson, C., Tracy-Smith, V., Bacigalupi, S. C., Othmer, S. (2007). Neurofeedback treatment for pain associated with complex regional pain syndrome. Journal of Neurotherapy, 11(1), 45–53.",
        "Pelletier, K. R., & Pepper, E. (1977). Developing a biofeedback model: Alpha EEG feedback as a means for pain control. International Journal of Clinical & Experimental Hypnosis, 25, 361–371.",
        "Rosenfeld, J. P., Dowman, R., Heinricher, N., & Silvia, R. (1984). Operantly controlled somatosensory evoked potentials: Specific effects on pain processes. Chapter in B. Rockstroh, T. Elbert, W. Lutzenberger, & N. Birbaumer (Eds.), Self-Regulation of the Brain and Behavior. Proceedings of the Second International Symposium on Biofeedback and Self-Regulation, held May 15-19, 1983 at the University of Tübingen. Berlin: Springer-Verlag, pp. 164–179.",
        "Rosenfeld, J. P., Silvia, R., Weitkunat, R., & Dowman, R. (1985). Operant control of human somatosensory evoked potentials alters experimental pain perception. Chapter in H. L. Fields, R. Dubner, & F. Cervero (Eds.), Advances in Pain Research and Therapy, Volume 9: Proceedings of the Fourth World Congress on Pain. New York: Raven Press, 343–349.",
        "Sime, A. (2004). Case study of trigeminal neuralgia using neurofeedback and peripheral biofeedback. Journal of Neurotherapy, 8(1), 59–71.",
        "Siniatchkin, M., Hierundar, A., Kropp, P., Kuhnert, R., Gerber, W-D., & Stephani, U. (2000). Self-regulation of slow cortical potentials in children with migraine: An exploratory study. Applied Psychophysiology & Biofeedback, 25(1), 13–32.",
        "Stokes, D. A., & Lappin, M. (2010) Neurofeedback and biofeedback with 37 migraineurs: a clinical outcome study. Behavioral and Brain Functions, 6(9)",
        "Tansey, M. A. (1991). A neurobiological treatment for migraine: The response of four cases of migraine to EEG biofeedback training. Headache Quarterly: Current Treatment & Research, 90–96.",
      ],
    },
    {
      title: "SEIZURES/EPILEPSY",
      articles: [
        "Achinivu, K., Staufenberg, E., Cull, C. (2012). Cognitive function during vagus nerve stimulation for treatment-refractory epilepsy: A pilot study using the critical flicker fusion test. Journal of Neurotherapy.",
        "Andrews, D. J., & Schonfeld, W. H. (1992). Predictive factors for controlling seizures using a behavioural approach. Seizure, 1(2), 111–116.",
        "Ayers, M. E. (1988). Long-term clinical treatment follow-up of EEG neurofeedback for epilepsy. Epilepsy Support Program Newsletter, 3(2), 8–9.",
        "Ayers, M. E. (1995). Long-term follow-up of EEG neurofeedback with absence seizures. Biofeedback & Self-Regulation, 20(3), 309–310.",
        "Birbaumer, N., Elbert, T., Rockstroh, B., Daum, I., Wolf, P., & Canavan, A. (1991). Clinical psychological treatment of epileptic seizures: A controlled study. Chapter in A. Ehlers et al. (Eds.), Perspectives and Promises of Clinical Psychology. New York: Plenum Press.",
        "Cott, A., Pavloski, R. P., & Black, A. H. (1979). Reducing epileptic seizures through operant conditioning of central nervous system activity: Procedural variables. Science, 203, 73–75.",
        "Daum, I., Rockstroh, B., Birbaumer, N., Elbert, T., Canavan, A., Lutzenberger, W. (1993). Behavioral treatment of slow cortical potentials in intractable epilepsy: Neuropsychological predictors of outcome. Journal of Neurosurgery & Psychiatry, 56 94–97.",
        "Elbert, T., Rockstroh, B., Canavan, A., Birbaumer, N., Lutzenberger, W., von Bulow, I., & Linden, A. (1990). Self-regulation of slow cortical potentials and its role in epileptogenesis. Chapter in J. Carlson & R. Seifert (Eds.), Biobehavioral Self-Regulation and Health. New York: Plenum Press.",
        "Elbertsen, B., & Klove, H. (1976). Clinical application of biofeedback training in epilepsy. Scandinavian Journal of Behavior Therapy, 5, 133–144.",
        "Engel, J., Troupin, A. S., Crandall, P. H., Sterman, M. B., & Wasterlain, C. G. (1982). Recent developments in the diagnosis and therapy of epilepsy. Annals of Internal Medicine, 97, 584–598.",
        "Finley, W. W. (1976). Effects of sham-feedback following successful SMR training in an epileptic: A follow-up study. Biofeedback & Self-Regulation, 1, 227–235.",
        "Finley, W. W. (1977). Operant conditioning of the EEG in two patients with epilepsy: Methodologic and clinical considerations. Pavlovian Journal of Biological Science, 12(2), 93–111.",
        "Finley, W. W., Smith, H. A., & Etherton, M. D. (1975). Reduction of seizures and normalization of the EEG in a severe epileptic following sensorimotor biofeedback training: Preliminary study. Biological Psychiatry, 2, 189–203.",
        "Fischer-Williams, M., & Clifford, B. C. (1988). Biofeedback treatment of patients with seizures: A pilot study of EEG feedback. Electroencephalography & Clinical Neurophysiology, 70(1), 18.",
        "Frey, L. (2016). Impact of sensorimotor rhythm neurofeedback on quality of life in patients with medically-refractory seizures. NeuroRegulation 3(1). 3-6.",
        "Frey, L.C., Koberda J.L. (2015). LORETA Z-score neurofeedback in patients with medically refractory epilepsy. Journal of Neurology and Neurobiology, 1(1).",
        "Hanson, L. M., Trudeau, D. L., & Grace, D. L. (1996). Neurotherapy and drug therapy in combination for adult ADHD, personality disorder, and seizure disorder: A case report. Journal of Neurotherapy, 2(1), 6–14.",
        "Kaplan, B. J. (1975). Biofeedback in epileptics: Equivocal relationship of reinforced EEG frequency to seizure reduction. Epilepsia, 16, 477–485.",
        "Koberda, J. L., Frey L. C. (2015). Z-score LORETA neurofeedback as a potential therapy for patients with seizures and refractory epilepsy. Journal of Neurology and Neurobiology, 1(1) doi: http://dx.doi. org/10.16966/noa.101",
        "Kotchoubey, B., Busch, S., Strehl, U., & Birbaumer, N. (1999). Changes in EEG power spectra during biofeedback of slow cortical potentials in epilepsy. Applied Psychophysiology & Biofeedback, 24(4), 213–233.",
        "Kotchoubey, B., Strehl, U., Uhlmann, C., Holzapfel, S., Konig, M., Froscher, W., Blankenhorn, V., & Birbaumer, N. (2001). Modification of slow cortical potentials in patients with refractory epilepsy: A controlled outcome study. Epilepsia, 42(3), 406–416.",
        "Kuhlman, W. N. (1978). EEG feedback training of epileptic patients: Clinical and electroencephalographic analysis. Electroencephalography & Clinical Neurophysiology, 45(6), 699–710.",
        "Kuhlman, W. N., & Allison, T. (1977). EEG feedback training in the treatment of epilepsy: Some questions and some answers. Pavlovian Journal of Biological Science, 12(2), 112–122.",
        "Lantz, D., & Sterman, M. B. (1988). Neuropsychological assessment of subjects with uncontrolled epilepsy: Effects of EEG biofeedback training. Epilepsia, 29(2), 163–171.",
        "Legarda, S., McMahon, D., Othmer, S., and Othmer, S. F. (2011). Clinical Neurofeedback: Case Studies, Proposed Mechanism, and Implications for Pediatric Neurology Practice. Journal of Child Neurology,(26)8:1045-1051.",
        "Lubar, J. F., & Bahler, W. W. (1976). Behavioral management of epileptic seizures following EEG biofeedback training of the sensorimotor rhythm. Biofeedback & Self-Regulation, 7, 77–104.",
        "Lubar, J. F., Shabsin, H. S., Natelson, S. E. et al. (1981). EEG operant conditioning in intractable epileptics. Archives of Neurology, 38, 700–704.",
        "Lubar, J. F., & Shouse, M. N. (1977). Use of biofeedback in the treatment of seizure disorders and hyperactivity. Advances in Clinical Child Psychology, 1, 204–251.",
        "Monderer, R. S., Harrison, D. M., & Haut, S. R. (2002). Review: Neurofeedback and epilepsy. Epilepsy & Behavior, 3, 214–218.",
        "Quy, R. J., Hutt, S. J., & Forrest, S. (1979). Sensorimotor rhythm feedback training and epilepsy: Some methodological and conceptual issues. Biological Psychology, 9, 129149.",
        "Rockstroh, B., Elbert, T., Birbaumer, N., Wolf, P., Duchting-Roth, A., Reker, M., Daum, I., Lutzenberger, W., & Dichgans, J. (1993). Cortical self-regulation in patients with epilepsies. Epilepsy Research, 14. 63–72.",
        "Rudrud, E., & Striefel, S. (1981). Eight to twelve hertz occipital EEG training with moderate and severely retarded epileptic individuals. Australian Journal of Developmental Disabilities, 7(4), 173–179.",
        "Seifert, A. R.,& Lubar, J. F. (1975). Reduction of epileptic seizures through EEG biofeedback training. Biological Psychology, 3, 157–184.",
        "Sterman, M. B., & Friar, L. (1972). Suppression of seizures in epileptics following sensorimotor EEG feedback training. Electroencephalography & Clinical Neurophysiology, 33, 89–95.",
        "Sterman, M. B. (1973a). Neurophysiological and clinical studies of sensorimotor EEG biofeedback training: Some effects on epilepsy. Seminars in Psychiatry, 5(4), 507–525.",
        "Sterman, M. B. (1973b). Neurophysiological and clinical studies of sensorimotor EEG biofeedback training: Some effects on epilepsy. Chapter in L. Birk (Ed.), Biofeedback: Behavioral Medicine. New York: Grune & Stratton, pp. 147–165.",
        "Sterman, M. B. (1977). Effects of sensorimotor EEG feedback on sleep and clinical manifestations of epilepsy. Chapter in J. Beatty & H. Legewie (Eds.), Biofeedback and Behavior. New York: Plenum, pp. 167–200.",
        " Sterman, M. B. (1977). Sensorimotor EEG operant conditioning: Experimental and clinical effects. Pavlovian Journal of Biological Sciences, 12(2), 63–92.",
        "Sterman, M. B. (1986). Epilepsy and its treatment with EEG feedback therapy. Annals of Behavioral Medicine, 8, 21–25.",
        "Sterman, M. B. (1997).The challenge of EEG biofeedback in the treatment of epilepsy: A view from the trenches. Biofeedback, 25(1), 6–7, 20–21, 23.",
        "Sterman, M. B. (2000). Basic concepts and clinical findings in the treatment of seizure disorders with EEG operant conditioning. Clinical Electroencephalography, 31(1), 4555.",
        "Sterman, M. B., Macdonald, L. R., & Stone, R. K. (1974). Biofeedback training of the sensorimotor electroencephalogram rhythm in man: Effects on epilepsy. Epilepsia, 15(3), 395–416.",
        "Sterman, M. B., & Macdonald, L. R. (1978). Effects of central cortical EEG feedback training on incidence of poorly controlled seizures. Epilepsia, 19(3), 207–222.",
        "Sterman, M. B., & Shouse, M. N. (1980). Quantitative analysis of training, sleep EEG and clinical response to EEG operant conditioning in epileptics. Electroencephalography & Clinical Neurophysiology, 49, 558–576.",
        "Sterman, M. B., & Lantz, D. (2001). Changes in lateralized memory performance in subjects with epilepsy following neurofeedback training. Journal of Neurotherapy, 5(1-2), 63–72.",
        "Sterman, M. B., & Egner, T. (2006). Foundation and practice of neurofeedback for the treatment of epilepsy. Applied Psychophysiology & Biofeedback, 31(1), 21–36.",
        "Strehl, U., Trevorrow, T., Veit, R., Hinterberger, T., Kotchoubey, B., Erb, M., & Birbaumer,N. (2006). Deactivation of brain areas during self-regulation of slow cortical potentials in seizure patients. Applied Psychophysiology & Biofeedback, 31(1), 85–94.",
        "Süreli, T., Ertem A. (2009). QEEG guided neurofeedback therapy in personality disorders: 13 case studies. Clinical EEG Neuroscience Journal. 40(1), 5-10.",
        "Swingle, P. G. (1998). Neurofeedback treatment of pseudo seizure disorder. Biological Psychiatry, 44(11), 1–4.",
        "Tan, G., Thornby, J., Hammond, D. C., Strehl, U., Canady, B., Arnemann, K., & Kaiser, D.K. (2009). Meta-analysis of EEG biofeedback in treating epilepsy. Clinical EEG & Neuroscience, 40(3), 173–179.",
        "Tansey, M. A. (1985). The response of a case of petit mal epilepsy to EEG sensorimotor rhythm biofeedback training. International Journal of Psychophysiology, 3, 81–84.",
        "Tozzo, C.A., Elfner, L. F., & May Jr., J. G. (1988). Biofeedback and relaxation training in the control of epileptic seizures. International Journal of Psychophysiology, 6, 185–194.",
        "Uhlmann, C., & Froscher, W. (2001). Biofeedback treatment in patients with refractory epilepsy: Changes in depression and control orientation. Seizure, 10(1), 34–38.",
        "Upton, A. R., & Longmere, D. (1975). The effects of feedback on focal epileptic discharges in man: A preliminary report. Canadian Journal of Neurological Sciences, 2, 153–167.",
        "Walker, J. E. (2008). Power spectral frequency and coherence abnormalities in patients with intractable epilepsy and their usefulness in long-term remediation of seizures using neurofeedback. Clinical EEG & Neuroscience, 39(4), 203–204.",
        "Walker, J. E., & Kozlowski, G. P. (2005). Neurofeedback treatment of epilepsy. Child & Adolescent Psychiatric Clinics of North America, 14(1), 163–176",
        "Whitsett, S. F., Lubar, J. F., Holder, G. S., et al. (1982). A double-blind investigation of the relationship between seizure activity and the sleep EEG following EEG biofeedback training. Biofeedback & Self-Regulation, 7, 193–209.",
        "Wyler, A. R., Robbins, C. A., & Dodrill, C. B. (1979). EEG operant conditioning for control of epilepsy. Epilepsia, 20, 279–286.",
        "Zhao, L., Liang, Z., Hu, G., & Wu, W. (2005). Nonlinear analysis in treatment of intractable epilepsy with EEG biofeedback. Conference Proceedings IEEE Engineering, Medical, & Biological Science, 5, 4568–4571",
      ],
    },
    {
      title: "SLEEP/INSOMNIA",
      articles: [
        "Arns, M., Swatzyna, R.J., Gunkelman, J., & Olbrich, S. (2015). Sleep maintenance, spindling excessive beta and regulatory systems approach?  Neuropsychiatric Electrophysiology electronically published June 2015.",
        "Bell, J. S. (1979). The use of EEG theta biofeedback in the treatment of a patient with sleep-onset insomnia. Biofeedback & Self Regulation, 4(3), 229–236.",
        "Berner, I., Schabus, M., Wienerroither, T., & Klimesch, W. (2006). The significance of sigma neurofeedback training on sleep spindles and aspects of declarative memory. Applied Psychophysiology & Biofeedback, 31(2), 97–114.",
        "Buckelew, S., Degood, D., Taylor. J., (2013). Neuroflexibilty and sleep onset insomnia among college students: Implication for neurotherapy. Journal of Neurotherapy.",
        "Feinstein, B., Sterman, M. B., & MacDonald, L. R. (1974). Effects of sensorimotor rhythm training on sleep. Sleep Research, 3, 134.",
        "Hammer, B. U., Colbert, A.P., Brown, K.A. and Ilioi, E. C. (2011). Neurofeedback for Insomnia: A Pilot Study of Z-Score SMR and Individualized Protocols. Applied Psychophysiology and Biofeedback, DOI 10.1007/s10484-011-9165-y",
        "Hammond, D. C. (2012). Neurofeedback treatment of restless legs syndrome and periodic leg movements in sleep. Journal of Neurotherapy.",
        "Hoedlmoser, K., Pecherstorfer, T., Gruber, E., Anderer, P., Doppelmayr, M., Klimesch, W., & Schabus, M. (2008). Instrumental conditioning of human sensorimotor rhythm (12–15 Hz) and its impact on sleep as well as declarative learning. Sleep, 31(10), 1401–1408.",
        "Sterman, M. B., Howe, R. D., & Macdonald, L. R. (1970). Facilitation of spindle-burst sleep by conditioning of electroencephalographic activity while awake. Science, 167, 1146–1148.",
      ],
    },
    {
      title: "Z SCORE NEUROFEEDBACK",
      articles: [
        "Collura, T. F., Guan, J., Tarrant, J., Bailey, J., & Starr, F. (2010). EEG biofeedback case studies using live Z-score training and a normative database. Journal of Neurotherapy, 14(1), 22–46.",
        "Collura, T. F. (2009). Neuronal dynamics in relation to normative electroencephalography assessment and training. Biofeedback, 36, 134–139.​​",
        "Frey, L.C., Koberda J.L. (2015). LORETA Z-score neurofeedback in patients with medically refractory epilepsy. Journal of Neurology and Neurobiology, 1(1). doi:",
        "Hammer, B.U., Colbert, A.P., Brown, K.A. and Ilioi, E. C. (2011). Neurofeedback for Insomnia: A Pilot Study of Z-Score SMR and Individualized Protocols. Applied Psychophysiology and Biofeedback, DOI 10.1007/s10484-011-9165-y",
        "Koberda, J.L. (2011). Clinical advantages of quantitative electroencephalogram (QEEG) application in general neurology practice. Neuroscience Letters, 500(Suppl.), e32.",
        "Koberda, J.L, Moses, A., Koberda, L. and Koberda, P. (2012). Cognitive enhancement using 19-Electrode Z-score neurofeedback. Journal of Neurotherapy, 16(3): 224-230.",
        "Koberda, J.L, Hiller, D.S., Jones, B., Moses, A., and Koberda, L. (2012). Application of Neurofeedback in general neurology practice. Journal of Neurotherapy, 16(3): 231-234.",
        "Koberda, J.L. (2014). Neuromodulation-An Emerging Therapeutic Modality in Neurology. Journal of Neurology and Stroke 2014, 1(4): 00027",
        "Koberda J, L. and Stodolska-Koberda U (2014). Z-score LORETA Neurofeedback as a Potential Rehabilitation Modality in Patients with CVA. Journal of Neurology and Stroke 1(5): 00029.",
        "Koberda, J.L. et al. 2012. Cognitive enhancement using 19-electrode Z-score Neurofeedback. Journal of Neurotherapy 3.",
        "Koberda JL, Koberda P, Bienkiewicz A, Moses A, Koberda L. Pain Management Using 19-Electrode Z-Score LORETA Neurofeedback. Journal of Neurotherapy, 2013, 17:3, 179-190.",
        "Koberda,J.L. (2012). Comparison of the effectiveness of Z-score Surface/LORETA 19- electrode Neurofeedback to standard 1-electrode Neurofeedback- Journal of Neurotherapy.",
        "Koberda, J. L. (2015). LORETA z-score neurofeedback-effectiveness in rehabilitation of patients suffering from traumatic brain injury. Journal of Neurology and Neurobiology, 1 (4).",
        "Koberda, J. L., Koberda, P., Moses, A., Winslow, J., Bienkiewicz, A., Koberda, L. (2014). Z-score LORETA neurofeedback as a potential therapy for ADHD. Biofeedback, 42(2), 74-81. doi: 10.5298/1081-5937-42.2.05",
        "Koberda, J. L. (2014). Z-score LORETA neurofeedback as a potential therapy in cognitive dysfunction and dementia. Journal of Psychology & Clinical Psychiatry, 1(6). doi: 10.15406/jpcpy.2014.01.00037",
        "Koberda, J. L., Frey L. C. (2015). Z-score LORETA neurofeedback as a potential therapy for patients with seizures and refractory epilepsy. Journal of Neurology and Neurobiology, 1(1) doi: http://dx.doi. org/10.16966/noa.101",
        "Krigbaum, G & Wigton, N (2014). When Discussing Neurofeedback, Does Modality Matter?. Journal of NeuroRegulation, 1(1), 48-60.",
        "Krigbaum, G., Wigton, N. (2015). A methodology of analysis for monitoring treatment progression with 19-channel z-score neurofeedback (19ZNF) in a single-subject design. Applied Psychophysiology & Biofeedback, 40(3), 139-149. doi: 10.1007/s10484-015-9274-0​",
        "Thompson, M., Thompson, L., & Reid, A. (2010). Functional Neuroanatomy and the Rationale for Using EEG Biofeedback for Clients with Asperger’s Syndrome. Journal of Applied Psychophysiology and Biofeedback, 35(1), 39-61.",
        "Thatcher, R.W. (2013): Latest Developments in Live Z-Score Training: Symptom Check List, Phase Reset, and Loreta Z-Score Biofeedback, Journal of Neurotherapy, 17(1), 69- 87.",
        "Thatcher, R.W. (2013). Latest Developments in Live Z-Score Training: Symptom Check List, Phase Reset, and Loreta Z-Score Biofeedback. Version of record first published: Journal of Neurotherapy. 17(1)",
        "Wigton, N.L. (2013) Clinical Perspectives of 19-Channel Z-Score Neurofeedback: Benefits and Limitations, Journal of Neurotherapy, 17(4), 259-264.",
      ],
    },
  ];

  const handleAccordion = (id) => {
    var target = document.getElementById(id);
    target.classList.toggle("accordion-open");
  };

  items.forEach((item) => {
    item.articles.forEach((article, i, array) => {
      var output = article.split(" ");
      var link = output.pop();
      if (link.includes("http")) {
        var altered = '<a href="' + link + '" target="_blank">Read Study</a>';
        output.push(altered);
      } else {
        output.push(link);
      }
      array[i] = "<p> - " + output.join(" ") + "</p>";
    });
  });

  return (
    <div className="reference">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>EC Neurotherapy - Resources</title>
        <meta
          name="description"
          content="Resources for learning more about Functional Brain Disorders and Neurofeedback treatment"
        />
        <link
          rel="canonical"
          href="https://eastcarolinabraincenter.com/reference"
        />
      </Helmet>
      <h1 className="heading-primary">Reference</h1>
      <div className="content__container">
        <p className="p">
          Are you interested in finding research articles regarding how learning
          occurs with neurofeedback and some of the conditions it can help with?
          Below is a list of references from only the last 5 years so
          that you may find the most current articles on PubMed.
        </p>
      </div>

      <div class="accordion">
        {items.map((item, i) => {
          let id = "item-" + i;
          return (
            <div className="accordion-item" key={id} id={id}>
              <div
                className="accordion-heading"
                onClick={() => handleAccordion(id)}
              >
                <h2 className="heading-secondary">{item.title}</h2>
                <span className="accordion-icon"></span>
              </div>
              <div
                className="accordion-body"
                dangerouslySetInnerHTML={{ __html: item.articles }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Reference;
