import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { AiFillPhone } from "react-icons/ai";

const Contact = () => {
  return (
    <div className="contact">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>EC Neurotherapy - Contact</title>
        <meta
          name="description"
          content="Home Services Neurofeedback Functional Neurology Functional Medicine Patients Your First Visit Our Approach Patient Intake Forms Payment Options Education"
        />

        <link
          rel="canonical"
          href="https://eastcarolinabraincenter.com/contact/"
        />
      </Helmet>
      <h4>We'd love to hear from you!</h4>
      
      <a className="btn-text" href="tel:252-412-3463" rel="nofollow">
        <AiFillPhone style={{ fontSize: "1.8rem", marginRight: ".5rem" }} />{" "}
        Call 252-412-3463
      </a>

      <p style={{margin: 0}}>OR</p>
      <p>Send us an email using the form below and we will reach out to you as soon as possible</p>
      <form name="contact" method="POST" className="contact__form">
        <h2 className="heading-primary">Contact</h2>
        <input type="hidden" name="form-name" value="contact" />
        <label htmlFor="name">Full name:</label>
        <input type="text" placeholder="" name="name" required />
        <label htmlFor="email">Email address:</label>
        <input type="email" placeholder="" name="email" required />
        <label htmlFor="subject">Subject:</label>
        <textarea
          name="message"
          placeholder="Please give a brief description of your symptoms or why you are seeking service"
          required
        ></textarea>
        <button type="submit">
          <h3>
            Send <FaPaperPlane />
          </h3>
        </button>
      </form>
    </div>
  );
};

export default Contact;
