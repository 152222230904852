import React from "react";
import { FaFileMedicalAlt, FaBrain } from "react-icons/fa";
import { BsArrowDownShort } from "react-icons/bs";
import { RiPsychotherapyFill } from "react-icons/ri";
import { GiProgression } from "react-icons/gi";
import { IoIosCall } from "react-icons/io";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CTA from "../components/callToAction.jsx";

const Treatment = () => {
  return (
    <div className="content content__container">
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>East Carolina Brain Center | Treatment</title>
        <meta
          name="description"
          content="Find out what neurofeedback is and how it can help with conditions like ADD, PTSD, Brain Injury, Autism, Sleep and learning disorders, Addiction and more."
        />
        <meta
          name="keywords"
          content="neurofeedback greenville,neuro,neuro feedback,clear mind,clearmind,neurotherapy greenville,neuro therapy,brainwaves,attention-deficit-disorder greenville,add greenville,depression greenville,anxiety greenville,memory loss greenville,brain training greenville,alertness,attention,emotional regulation,behavior,cognitive function,and mental flexibility,brainwave functions"
        />
        <link
          rel="canonical"
          href="https://eastcarolinabraincenter.com/neurofeedback/"
        />
      </Helmet>
      <h2 className="heading-primary"> Treatment</h2>
      <h2 className="heading-secondary">Our approach:</h2>
      <div className="card-container mobile">
        <div className="card-column">
          <div
            className="service-card"
            data-aos="fade-down"
            data-aos-offset="50"
            data-aos-duration="1600"
          >
            <IoIosCall className="service-card-icon" />
            <a href="tel:252-412-3463" style={{ color: "#003663" }}>
              <h2>1. Contact Us</h2>{" "}
            </a>

            <p>
              Tell us about yourself through our questionnaires and
              consultation. This allows us to outline your symptoms and provide
              an individualized approach to treatment.
            </p>
          </div>
        </div>
        <p className="service-card-icon service-card-icon--1 mobile">
          <BsArrowDownShort />
        </p>
        <div className="card-column">
          <div
            className="service-card"
            data-aos="fade-down"
            data-aos-offset="50"
            data-aos-duration="1600"
            data-aos-delay="300"
          >
            <FaFileMedicalAlt className="service-card-icon" />
            <h2>2. Assessment</h2>
            <p>
              We use Quantitative Electroencephalograpic (qEEG) data collection
              and interpretation to assess and measure your current level of
              brain function. This provides a "Brain Map" for treatment.
            </p>
          </div>
          <p className="service-card-icon service-card-icon--1 mobile">
            <BsArrowDownShort />
          </p>
        </div>{" "}
        <div className="card-column">
          <div
            className="service-card"
            data-aos="fade-down"
            data-aos-offset="50"
            data-aos-duration="1600"
            data-aos-delay="400"
          >
            <RiPsychotherapyFill className="service-card-icon" />
            <h2>3. Training</h2>
            <p>
              Neurofeedback training is used to correct abnormal brain activity
              and gradually restore the brain's connections to a normal level of
              functioning.
            </p>
          </div>
          <p className="service-card-icon service-card-icon--1 mobile">
            <BsArrowDownShort />
          </p>
        </div>
        <div className="card-column">
          <div
            className="service-card"
            data-aos="fade-down"
            data-aos-offset="50"
            data-aos-duration="1600"
            data-aos-delay="500"
          >
            <GiProgression className="service-card-icon" />
            <h2>4. Results</h2>
            <p>
              The goal of treatment is to reduce symptoms and behaviors, and in
              turn improve quality of life. These changes are measurable and
              reflected on new qEEG scans!
            </p>
          </div>
        </div>
      </div>
      <div className="large-cards desktop">
        <div className="card-column">
          <div className="service-card">
            <IoIosCall className="service-card-icon" />
            <a href="tel:252-412-3463" style={{ color: "#003663" }}>
              <h2>1. Contact Us</h2>{" "}
            </a>
          </div>
        </div>
        <p className="service-card-icon service-card-icon--1 mobile">
          <BsArrowDownShort />
        </p>
        <div className="card-column">
          <div className="service-card">
            <FaFileMedicalAlt className="service-card-icon" />
            <h2>2. Assessment</h2>
          </div>
          <p className="service-card-icon service-card-icon--1 mobile">
            <BsArrowDownShort />
          </p>
        </div>{" "}
        <div className="card-column">
          <div className="service-card">
            <RiPsychotherapyFill className="service-card-icon" />
            <h2>3. Training</h2>
          </div>
          <p className="service-card-icon service-card-icon--1 mobile">
            <BsArrowDownShort />
          </p>
        </div>
        <div className="card-column">
          <div className="service-card">
            <GiProgression className="service-card-icon" />
            <h2>4. Results</h2>
          </div>
        </div>
      </div>
      <div className="content__container">
        <h2 className="heading-primary">1. Contact</h2>
        <div className="p">
          <Link to="/contact" style={{ color: "#003663" }}>
            Call or send us an email
          </Link>{" "}
          for an initial consulation. During your consultation, severity of
          symptoms and behaviors are reported through patient questionnaires and
          interview.&nbsp;
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          These symptoms and behaviors being experienced are linked to specific
          areas in the brain. After identifying the areas and connections
          specific to your symptoms, an individualized training plan is created
          to begin training the brain back to a level of normal functioning.
        </div>
        <h2 className="heading-primary">2. Assessment</h2>
        <div className="p">
          qEEG is the best method for understanding the electrical activity of
          the brain. It can identify problems in one's ability to process
          information, make connections between brain networks, and send and
          receive clear information in a timely manner. At East Carolina
          Neurotherapy, we use the latest, most advanced and targeted technology
          available: swLORETA z-score neurofeedback.
        </div>
        <img
          loading="lazy"
          src={require("../assets/qeeg-4.png").default}
          alt="QEEG"
          className="img-default"
        />
        <p className="caption">
          This is an example of the summarized information obtained from the
          QEEG (Quantitative Electroencephalograph), commonly called a Brain
          Map.
        </p>
        <div className="p">
           Traditional forms of neurofeedback treatment typically use an EEG cap
          with 1-6 sensors. This method only allows the computer to read and
          train generalized areas of the brain. Our method of neurofeedback
          treatment utilizes a 19-sensor EEG cap and an advanced brain imaging
          technique called standardized weighted low resolution electromagnetic
          tomography analysis (swLORETA).  It's like a GPS for brain activity,
          showing where the activity is, where it is going, and how fast it will
          get there.
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          <div className="desktop">
            <br />
          </div>
        </div>
        <img
          loading="lazy"
          src={require("../assets/eeg-cap.jpg").default}
          alt="qeeg cap"
          className="img-default"
        />
        <p className="caption" style={{ width: "60%" }}>
          EEG cap
        </p>
        <div className="p">
          &nbsp; &nbsp; &nbsp;Our sophicated EEG is the only non-invasive and
          inexpensive measure to see the real-time learning and flow of
          information through the brain. It measures the electrical activity in
          the brain, and then the results are compared to well functioning
          brains for each age and gender. The person's symptoms and behaviors
          are linked to the presence of dysfunction in brain areas and networks
          of connections.
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          This demonstrates how thoughts, feelings, abilities, and behaviors are
          expressed in Functional Brain Disorders. The following illustration of
          the midline brain area networks are most involved in disorders of
          mood, emotion, self perception and memory. Identifying the location of
          these brain processing areas and how they are communicating is an
          important question that is answered with the QEEG Brain Map.
        </div>
        <img
          loading="lazy"
          src={require("../assets/qeeg-3.png").default}
          alt="qeeg cap"
          className="services__infobox__cap"
        />

        <Link to="/reference" className="btn-text">
          <FaBrain style={{ fontSize: "2rem" }} />
          &nbsp; "Deep dive" into neurofeedback &rarr;
        </Link>
        <h2 className="heading-primary">3. Training</h2>
        <div className="p">
          Neurotherapy is a safe, effective, long term approach for improving
          dysfunctional brain activity. Neurotherapy methods engage the brain's
          ability to recognize desirable and undesirable brainwave activity to
          create improved brain connections and patterns.
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          Our method of training aims to identify and teach the brain hubs and
          connections in real time. This shows a 3D image of real time brain
          activity and pinpoints specifically where the source of activity is
          located. It can visualize more areas in the cortex and subcortical
          areas, such as the cerebellum and thalamus, deeper down in the brain.
          For this reason, the modality is often called “deep brain
          neurofeedback.”
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
        </div>
        <img
          loading="lazy"
          src={require("../assets/mood-spectrum.jpg").default}
          alt="qeeg cap"
          className="img-default"
        />
        <div className="desktop">
          <br />
        </div>
        <div className="p">
          &nbsp; &nbsp; &nbsp; By comparing brain activity against a normative
          database of “healthy brains” we identify regions and patterns that
          show dysfunctional brain activity outside these levels. This gives us
          a z score comparison (i.e. a statistical measurement) of how far the
          activity in a particular region is outside the “healthy” range.
          Correlating the dysfunctional brain hubs and connections that are
          outside these healthy ranges with unwanted symptoms/behaviors is an
          important step in setting up the brain training protocol.{" "}
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          We select the hubs and network connections that are determined to
          underlie the symptoms/behaviors and simultaneously train them toward
          healthier functioning. As a result, swLORETA z score neurofeedback is
          one of the most precise, targeted, and individualized brain-improving
          tools available.
        </div>
        <h2 className="heading-primary">4. Results</h2>
        <div className="p">
          Neurofeedback treatment promotes the natural process of experiential
          learning. During treatment, positive changes in the brainwave activity
          are promoted while unwanted changes are discouraged. Instead of just
          learning facts, the brain is learning how to regulate its own
          processing abilities by shaping the brain network interactions.
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          Throughout the process, the brain's awareness of positive and negative
          experiences improves. For example, relaxing and breathing slowly may
          help produce a desired brain activity while tensing up or daydreaming
          does not produce a desired brain activity. Much of what the brain does
          is without conscious control. Brain training targets both what we do
          and do not consciously control through the neurofeedback process.{" "}
          <div className="mobile">
            &nbsp;
            <hr />
          </div>
          Each session, progressive changes are measured and the overall changes
          from training sessions are compared to the initial QEEG findings. Over
          time, with repetition, the new brain network activation patterns
          become stronger and more established resulting in long term changes.
        </div>
        <img
          loading="lazy"
          src={require("../assets/thoughts.jpg").default}
          alt="QEEG"
          className="img-default"
        />
        <CTA />
        {/* <div className="services__infobox">
          <h2 className="heading-primary">Neurofeedback Training</h2>
          <h3 className="heading-secondary">(Therapy)</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo
            possimus sit libero tempora veniam quidem soluta nostrum at, quae
            tenetur quos voluptatem vitae adipisci ullam debitis, distinctio
            culpa laborum blanditiis?
          </p>
        </div> */}
      </div>
      {/*


The electrical brainwave activity is measured by sophisticated EEG equipment and the results
are quantifiably compared to a normative reference database of well functioning brains for each
age and gender. The persons symptoms and behaviors are linked to the presence of dysfunction
in brain areas and networks of connections. The EEG is the only non-invasive and inexpensive
measure of the real-time creation and flow of information through the brain for understanding
how thoughts, feelings, abilities and behaviors are expressed in Functional Brain Disorders.


Information flows through the brain networks in a loop streaming
countless bits of information from our sensory system, thoughts, emotions and
actions. The categories of Bottom Up sensory information about what and where
a thing is and if there is movement or change occurring, and the Top Down
executive decision processing of knowing what to do, how to do, when to begin
doing, when not to begin, when to continue doing, and when to stop doing.
The basic model involves what we call “bottom up” and “top down” processing.
“Bottom up” refers to the information coming from our sensory systems
( including our emotions, memories, motivational drives, bodily functions)
meaning we have basic information being available for selection and
interpretation by our higher cognitive areas. “Top Down” involves selecting out of
all those bits of information the most meaningful bits that can be used to predict
the results of actions, progression of thoughts, or emotions. It is important to
think of the brain organizing emotions, thoughts, and internal bodily activities in
a way that is parallel to the way it is processing motor actions.

        Results - 



        Overview - Medicinal alternatives to Neurotherapy can seem to be a convenient solution in suppressing the
symptoms and behaviors of a Functional Brain Disorder. Our society is all too familiar with the issues of
drug dependence, adverse side effects and long term risks. Neurotherapy is a safe, effective, long term
approach for improving the underlying problem of dysfunctional brain activity

Neurotherapy methods engage the brains ability to recognize desirable (meaningful experiences,
normal behaviors and optimal abilities) and undesirable (unwanted experiences, bothersome behaviors
and substandard abilities) brainwave activity and create new functional circuits and patterns. A brain
that continues to learn with new processing capabilities. It’s only natural!


    Credibility - 
      
       */}
    </div>
  );
};

export default Treatment;
